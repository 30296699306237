<template>
  <div class="wrapper" v-show="loading">
    <div class="sub-wrapper">
        <Loader/>
    </div>
</div>
<div v-if="gateway_timeout > 5" class="alert alert-custom alert-indicator-left indicator-danger" style="margin-top: 20px" role="alert">
    <div class="alert-content">
        <span class="alert-text" style="font-size: large;">
            There appears to be an issue with our services, we are trying to resolve the issue as quickly as possible.
        </span>
    </div>
</div>
<div class="" v-if="!loading">

  <div class="row">
    <!-- Select site group -->
    <div v-if="siteGroups.length > 0" class="col-xl-3 p-v-sm m-b-md">
        <div class="form-group">
            <label for="selectSite" class="form-label"> Select Site Group </label>
            <Multiselect
                v-model="siteGroup"
                :class="{'multi-dark': $store.state.auth.status.dark}"
                mode="single"
                placeholder="Select a site group"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :multiple="false"
                :options="siteGroups"
                label="name"
                track-by="id"
                name="sitegroup"
                @select="(value) => loadSiteGroupData(value)"
                @remove="removeSiteGroup"
            />
        </div> 
    </div>
    <div class="col-4 p-v-sm m-b-md">
        <div class="form-group">
            <label for="selectSite" class="form-label"> {{$t('dashboard.select_site')}} </label>
            <div class="row" style="display: flex;">
                <div class="col-12 col-md-8" style="display: inline-block;">
                    <Multiselect
                        v-model="site"
                        :class="{'multi-dark': $store.state.auth.status.dark}"
                        mode="single"
                        placeholder="Select a site"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="true"
                        :multiple="false"
                        :options="sites"
                        label="name"
                        track-by="id"
                        name="site"
                        @select="(value) => loadData(value)"
                        @remove="removeSite"
                    />
                </div>
                <div class="col-6 col-md-4" style="display: inline-block;">
                    <button class="btn btn-primary space-between" v-show="site != null" @click="resetSiteFilter()">Show all</button>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="row">

    <div class="col-4">
      <div class="card widget widget-stats">
        <div class="card-body">
            <div class="widget-stats-container d-flex">
                <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title">Total Faces Seen</span>
                    <span class="widget-stats-amount">{{ totalFaces.toLocaleString() }}</span>
                    <span class="widget-stats-info">Last 30 days</span>
                </div>
                <!-- <div class="widget-stats-indicator widget-stats-indicator-negative align-self-start">
                    <i class="material-icons">keyboard_arrow_down</i> 4%
                </div> -->
            </div>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="card widget widget-stats">
        <div class="card-body">
            <div class="widget-stats-container d-flex">
                <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title">Busiest Day</span>
                    <span class="widget-stats-amount">{{ busiestDay.date }}</span>
                    <span class="widget-stats-info">{{ busiestDay.total.toLocaleString() }} Faces</span>
                </div>
                <!-- <div class="widget-stats-indicator widget-stats-indicator-negative align-self-start">
                    <i class="material-icons">keyboard_arrow_down</i> 4%
                </div> -->
            </div>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="card widget widget-stats">
        <div class="card-body">
            <div class="widget-stats-container d-flex">
                <div class="widget-stats-content flex-fill">
                    <span class="widget-stats-title">Busiest Hour</span>
                    <span class="widget-stats-amount">{{ busiestHour.hour }}</span>
                    <span class="widget-stats-info">{{ busiestHour.total.toLocaleString() }} Faces</span>
                </div>
                <!-- <div class="widget-stats-indicator widget-stats-indicator-negative align-self-start">
                    <i class="material-icons">keyboard_arrow_down</i> 4%
                </div> -->
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <h5>Current Bandings</h5>
      
      <table class="table table-borderless">
        <thead>
          <tr>
            <th>Site</th>
            <th>Average Daily Faces</th>
            <th># of Alerts</th>
            <th>Band</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="site in data.sites">
            <td>{{ site.name }}</td>
            <td>{{ site.average }}</td>
            <td>{{ site.alerts }}</td>
            <td>{{ site.band }}</td>
            <td>£{{ site.charge }}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>

  <div class="modal fade" id="awaitingEvidenceModal" tabindex="-1" aria-labelledby="awaitingEvidenceModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="margin-inline: auto;">
          <h5 class="modal-title">Incidents Awaiting Evidence</h5>
          <button type="button" style="color: white;" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="margin-inline: auto;" v-if="aeincidents.incidents">
          <p>{{ "There are currently " + this.aeincidents.total + " Incidents Awaiting new Evidence" }}</p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(incident, index) in incidentsWithTimeLimit" :key="index">
              <div class="d-flex justify-content-between" @click="goToIncidents(incident.id, site.id)">
                <span>{{ "Incident " + incident.id }}</span>
                <span :class="{
                  'text-success': incident.daysLeft > 10,
                  'text-danger': incident.daysLeft < 4,
                  'text-warning': incident.daysLeft >= 4 && incident.daysLeft <= 10 || incident.daysLeft === 'Undetermined'
                }">
                  {{ incident.daysLeft + " days left" }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import dashboardServices from '../../../services/dashboard.services';
import { Multiselect } from 'vue-multiselect';
import clientService from '../../../services/client.service';
import axios from 'axios';
import authHeader from '../../../services/auth-header';
import { DateTime } from 'luxon';
import Loader from './Loader.vue';

export default {
  name: 'FaceDashboard',
  components: {
    Multiselect,
    Loader
  },
  data(){
    return {
      loading: true,
      data: {
        
      },
      site: null,
      sites: [],
      siteGroup: null,
      siteGroups: [],
      selectedSiteGroup: null,
      aeincidents: [],
      awaitingEvidenceModal: null,
      gateway_timeout: 0
    }
  },
  mounted(){
    this.getSiteGroups();
    this.getSites();
    this.loadData(null, null);

    this.awaitingEvidenceModal = new bootstrap.Modal(document.getElementById('awaitingEvidenceModal'), {
      keyboard: false
    });
  },
  watch: {
    site(newVal, oldVal) {
      if (newVal !== -1) {
        this.getAwaitingEvidenceIncidents();
      }
    }
  },
  methods: {
    getSites() {
        clientService.getClientSites(this.$store.state.auth.user.selectedClient, false, this.siteGroup, 5)
        .then(response => {
            response.data = response.data.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            response.data.forEach((site) => {
                if(site.customer_reference){
                    site.name = `(${site.customer_reference}) ${site.name}`;
                }
            })
            this.sites = response.data;
            if(this.sites.length > 0 && this.sites.length < 2){
                this.site = this.sites[0];
            }
        })
        .catch(error => {
            this.$error(this.$t('sites.site_load_error'), error);
            if (error.response.status === 504) {
              this.gateway_timeout += 1;
            }
        })
    },
    getSiteGroups(){
        clientService.getClientSiteGroups(this.$store.state.auth.user.selectedClient)
        .then(response => {
            this.siteGroups = response.data;
        })
        .catch(error => {
            this.$error(this.$t('sites.site_load_error'), error);
            if (error.response.status === 504) {
              this.gateway_timeout += 1;
            }
        });
    },
    loadSiteGroupData(value){
        this.siteGroup = value.id;
        this.getSites();
        this.loadData(this.site, this.siteGroup)
    },
    removeSiteGroup(){
        this.siteGroup = null;
        this.getSites();
        this.loadData(this.site, null)
    },
    resetSiteFilter() {
        this.site = -1;
        this.site = null;
        this.siteGroup = null;
        this.loadData(null, this.siteGroup);
    },
    loadData(site, siteGroup){
        
        axios.get(`https://api.varsanpr.com/customer/face/dashboard`, {
          headers: authHeader(),
          params: {
            client_id: this.$store.state.auth.user.selectedClient,
            site_id: site ? site.id : null,
            site_group_id: siteGroup ? siteGroup : null,
            start_date: DateTime.now().minus({days: 30}).toFormat('yyyy-MM-dd'),
            end_date: DateTime.now().toFormat('yyyy-MM-dd')
          }
        })
        .then(response => {
          this.data = response.data;
        })
        .catch(error => {
          this.$error('Failed to load data', error);
          if (error.response.status === 504) {
            this.gateway_timeout += 1;
          }
        })
        .finally(() => {
          this.loading = false;
        });

    },
    getAwaitingEvidenceIncidents() {
      if (this.site.id === -1) return;
      dashboardServices.getAwaitingEvidenceIncidents(this.site.id)
      .then(response => {
        this.aeincidents = response.data;

        if (this.aeincidents.incidents && this.aeincidents.incidents.length > 0) {
          this.awaitingEvidenceModal.show();
        }
      })
      .catch(error => {
        this.$error("Failed to load awaiting evidence incidents", error);
        if (error.response.status === 504) {
          this.gateway_timeout += 1;
        }
      })
    },
    goToIncidents(incident_id, site_id) {
      this.awaitingEvidenceModal.hide();
      this.$router.push(`/incidents/${incident_id}?site_id=${site_id}`);
    },
  },
  computed: {
    totalFaces: function(){
      let total = 0;
      for (let key in this.data.faces) {
        if (Object.hasOwnProperty.call(this.data.faces, key)) {
          let element = this.data.faces[key];
          for (let key2 in element) {
            if (Object.hasOwnProperty.call(element, key2)) {
              let element2 = element[key2];
              total += element2;
            }
          }
        }
      }
      return total;
    },
    busiestDay: function(){
      let busiest = {
        date: null,
        total: 0
      };
      for (let key in this.data.faces) {
        if (Object.hasOwnProperty.call(this.data.faces, key)) {
          let element = this.data.faces[key];
          let total = 0;
          for (let key2 in element) {
            if (Object.hasOwnProperty.call(element, key2)) {
              let element2 = element[key2];
              total += element2;
            }
          }
          if(total > busiest.total){
            busiest.total = total;
            busiest.date = key;
          }
        }
      }
      return busiest;
    },
    busiestHour: function(){
      let busiest = {
        hour: null,
        total: 0
      };
      for (let key in this.data.faces) {
        if (Object.hasOwnProperty.call(this.data.faces, key)) {
          let element = this.data.faces[key];
          for (let key2 in element) {
            if (Object.hasOwnProperty.call(element, key2)) {
              let element2 = element[key2];
              if(element2 > busiest.total){
                busiest.total = element2;
                busiest.hour = key2;
              }
            }
          }
        }
      }
      busiest.hour = busiest.hour.replace('_', ':00 - ') + ':00';
      return busiest;
    },
    incidentsWithTimeLimit() {
      const fourteenDaysInMillis = 14 * 24 * 60 * 60 * 1000;
      const currentDate = new Date();

      return this.aeincidents.incidents.map(incident => {
        if (!incident.latest_evidence) {
          return {
            ...incident,
            daysLeft: 'Undetermined'
          };
        } else {
          const latestEvidenceDate = new Date(incident.latest_evidence);
          const timeDiff = latestEvidenceDate.getTime() + fourteenDaysInMillis - currentDate.getTime();
          const daysLeft = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));

          return {
            ...incident,
            daysLeft
          };
        }
      });
    },
  }
}
</script>

<style scoped>
.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
}

.sub-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>