<template>
    <div class="wrapper" v-show="loading">
        <div class="temp">
            <Loader />
        </div>
    </div>
    <div v-if="!loading">
        <!-- Select site -->
        <div class="form-group col-5 p-v-sm">
            <label for="selectSites" class="form-label"> {{$t('dashboard.select_site')}} </label>
            <select id="selectSites" class="form-select" v-model="selectedSite" @change="loadSiteDashboard">
                <option value="-1" selected> ALL AVAILABLE </option>
                <option v-for="site in sites" :key="'site-' + site.id" :value="site.id"> {{site.name}} </option>
            </select>
        </div>

        <!-- Today's data -->
        <h4 class="section-heading p-v-sm"> TODAY'S DATA </h4>
        <div class="row">

            <!-- Today's payments -->
            <div :class="{'col-xl-4': stats.pump !== undefined || stats.site !== undefined, 'col-xl-6': stats.pump !== undefined && stats.site !== undefined}" @click="openTodaysReport('paid')">
                <div class="card widget widget-stats todays-data">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-success">
                                    <i class="material-icons-outlined"> {{currency}} </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <span class="widget-stats-title"> {{$t('dashboard.todays-payments')}} </span>
                                <span class="widget-stats-amount">
                                    <!-- <span v-if="selected_client.locale === 'en-GB'"> £ </span>
                                    <span v-else> € </span> -->
                                    <span> {{currency === 'currency_pound' ? '£' : '€'}} </span>
                                    <span> {{parseFloat(stats.paid.today || 0).toFixed(2)}} </span>
                                </span>
                                <span class="widget-stats-info"> {{currency === 'currency_pound' ? '£' : '€'}} {{(stats.paid.yesterday || 0).toFixed(2)}} {{$t('dashboard.yesterday')}} </span>
                            </div>
                            <div class="widget-stats-indicator align-self-start" :class="paid_percentage.icon === 'keyboard_arrow_up' ? 'widget-stats-indicator-positive' : 'widget-stats-indicator-negative'">
                                <i class="material-icons"> {{paid_percentage.icon}} </i>
                                {{paid_percentage.value}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Today's losses -->
            <div :class="{'col-xl-4': stats.pump !== undefined || stats.site !== undefined, 'col-xl-6': stats.pump !== undefined && stats.site !== undefined}"  @click="openTodaysReport('lost')">
                <div class="card widget widget-stats todays-data">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-danger">
                                    <i class="material-icons-outlined"> {{currency}} </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <span class="widget-stats-title"> {{$t('dashboard.todays-losses')}} </span>
                                <span class="widget-stats-amount">
                                    <!-- <span v-if="selected_client.locale === 'en-GB'"> £ </span>
                                    <span v-else> € </span> -->
                                    <span> {{currency === 'currency_pound' ? '£' : '€'}}</span>
                                    <span> {{parseFloat(stats.lost.today || 0).toFixed(2)}} </span>
                                </span>
                                <span class="widget-stats-info"> {{currency === 'currency_pound' ? '£' : '€'}} {{(stats.lost.yesterday || 0).toFixed(2)}} {{$t('dashboard.yesterday')}} </span>
                            </div>
                            <div class="widget-stats-indicator align-self-start" :class="paid_percentage.icon === 'keyboard_arrow_up' ? 'widget-stats-indicator-positive' : 'widget-stats-indicator-negative'">
                                <i class="material-icons"> {{paid_percentage.icon}} </i>
                                {{paid_percentage.value}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Worst Site -->
            <div v-if="stats.site !== undefined" :class="stats.pump === undefined ? 'col-xl-4' : 'col-xl-6'" @click="openTodaysReport('', stats.site.site.id)">
                <div class="card widget widget-stats todays-data">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-warning">
                                    <i class="material-icons-outlined"> car_crash </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <span class="widget-stats-title"> {{$t('dashboard.worst_site')}} </span>
                                <span class="widget-stats-amount"> {{stats.site.site.name}} </span>
                                <span class="widget-stats-info" style="padding: 10px;"> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Worst Pump -->
            <div v-if="stats.pump !== undefined" :class="stats.site === undefined ? 'col-xl-4' : 'col-xl-6'" @click="openTodaysReport('', stats.site.site.id)">
                <div class="card widget widget-stats todays-data">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-primary">
                                    <i class="material-icons-outlined"> local_gas_station </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <span class="widget-stats-title"> {{$t('dashboard.worst_pump')}} </span>
                                <span class="widget-stats-amount"> {{stats.pump.pump === 0 ? 'No Site' : stats.pump.pump}} </span>
                                <span class="widget-stats-info">£{{parseFloat(stats.pump.lost || 0).toFixed(2)}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="section-heading p-v-sm"> {{$t('dashboard.currently_processing').toUpperCase()}} </h4>
        <div class="row">
            <div class="col-xl-3" @click="openProcessingClaims">
                <div class="card todays-data">
                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('sites.invoice.total', {text: 'Claims'}) }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{total_claims}} </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3" @click="openProcessingClaims('DRIVEOFF')">
                <div class="card todays-data">
                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('global.driveoff') }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{claims.driveOff}} </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3" @click="openProcessingClaims('NMOP')">
                <div class="card todays-data">
                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('global.nmop') }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{claims.nmop}} </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3" @click="openProcessingClaims('PARKING')">
                <div class="card todays-data">
                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('global.parking') }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{claims.parking}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="card m-b-xxl">
            <div class="card-body">
                <div class="grid">
                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('sites.invoice.total', {text: 'Claims'}) }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{total_claims}} </span>
                        </div>
                    </div> 

                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('global.driveoff') }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{claims.driveOff}} </span>
                        </div>
                    </div>

                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('global.nmop') }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{claims.nmop}} </span>
                        </div>
                    </div>
                    
                    <div class="flex-center"> 
                        <div class="card-title" style="padding: 0; text-align: center;"> 
                            <h5> {{ $t('global.moneyLost') }} </h5>
                            <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                        </div>
                        <div class="temp2">
                            <span> {{claims.moneyLost}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-9"> <h4 class="section-heading p-v-sm"> {{$t('dashboard.recovery').toUpperCase()}} </h4> </div>
            <div class="col-3"> <h4 class="section-heading p-v-sm"> {{$t('dashboard.money_lost').toUpperCase()}} </h4> </div>
        </div>
        <!-- <h4 class="section-heading p-v-sm"> RECOVERY </h4> -->
        <div class="row">

            <!-- RECOVERY -->
            <div class="accordion col-9" id="recoveryAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="recoveryHeading">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#recovery" aria-expanded="false" aria-controls="filters">
                            ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="recovery" class="accordion-collapse collapse show" aria-labelledby="recoveryHeading" data-bs-parent="#recovery">
                        <div class="accordion-body">
                            <div class="row" style="justify-content: space-around;">
                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.last_month).toFixed(2) || 0}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.current_month).toFixed(2) || 0}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Year'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.money_reclaimed).toFixed(2)}} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-t-md" style="width: fit-content; margin: auto;">
                                <span style="cursor: pointer;"  @click="toggleSwitch('recovery')"> {{$t('dashboard.previous_year_data')}} <i class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> </span>
                            </div>
                            <div v-show="previousYear.recovery" class="row" style="justify-content: space-around;">
                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.last_month).toFixed(2)}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.current_month).toFixed(2)}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Year'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.money_reclaimed).toFixed(2)}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <h4 class="section-heading p-v-sm"> RECOVERY </h4> -->
            <div class="accordion col-3" id="moneyLostAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="moneyLostHeading">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#moneyLost" aria-expanded="false" aria-controls="filters">
                            ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="moneyLost" class="accordion-collapse collapse show" aria-labelledby="moneyLostHeading" data-bs-parent="#moneyLost">
                        <div class="accordion-body">
                            <div class="col-12" style="width: fit-content; margin: auto;">
                                <div class="m-b-xs"> {{$t('dashboard.outstanding')}} </div>
                                <div class="recovery-stats bg-danger">
                                    <span> £{{parseFloat(claims.money_lost).toFixed(2)}} </span>
                                </div>
                            </div>
                            <div class="p-t-md" style="width: fit-content; margin: auto;">
                                <span class="p-t-md" @click="toggleSwitch('moneyLost')"> {{$t('dashboard.previous_year_data')}} <i class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> </span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row  p-v-sm">
            <div class="col-9"> <h4 class="section-heading"> {{$t('global.parking').toUpperCase()}} </h4> </div>
            <div class="col-3"> <h4 class="section-heading"> {{$t('dashboard.active_parking_sites').toUpperCase()}} </h4> </div>
        </div>
        <div class="row">
            <div class="accordion col-9" id="parkingAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="parkingHeading">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#parking" aria-expanded="false" aria-controls="filters">
                            ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="parking" class="accordion-collapse collapse show" aria-labelledby="parkingHeading" data-bs-parent="#parking">
                        <div class="accordion-body">
                            <div class="row" style="justify-content: space-around;">
                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.parking_last_month).toFixed(2)}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.parking_curr_month).toFixed(2)}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Year'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.parking_total).toFixed(2)}} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-t-md" style="width: fit-content; margin: auto;">
                                <span class="" @click="toggleSwitch('parking')"> {{$t('dashboard.previous_year_data')}} <i class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> </span>
                            </div>
                            <div v-show="previousYear.parking" class="row" style="justify-content: space-around;">
                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.parking_last_month).toFixed(2)}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Month'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.parking_current_month).toFixed(2)}} </span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Year'})}} </div>
                                    <div class="recovery-stats">
                                        <span> £{{parseFloat(claims.parking_total).toFixed(2)}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion col-3" id="parkingSitesAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="parkingHeading">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#parkingSites" aria-expanded="false" aria-controls="filters">
                            ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="parkingSites" class="accordion-collapse collapse show" aria-labelledby="parkingSitesHeading" data-bs-parent="#parkingSites">
                        <div class="accordion-body">
                            <div class="col-12" style="width: fit-content; margin: auto;">
                                <div class="p-v-sm"> {{}} </div>
                                <div class="recovery-stats bg-primary">
                                    <span> {{claims.parking_sites}} </span>
                                </div>
                            </div>
                            <div class="p-t-md" style="width: fit-content; margin: auto;">
                                <span class="p-t-md"> {{$t('dashboard.previous_year_data')}} <i class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- BLACKLIST -->
        <div class="col-xl-6 m-t-sm">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title"> {{$t('global.blacklist')}}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 custom flex">
                            <span> {{$t('dashboard.today')}} </span>
                            <div class="amount"> {{blacklist.today}}</div>
                            <div style="text-align: end;"> <div class="status-container"> ^ 100% </div> {{$t('dashboard.from', {text: 'yesterday'})}} </div>
                        </div>
                        <div class="col-6 custom flex">
                            <span> {{$t('dashboard.this', {text: 'Week'})}} </span>
                            <div class="amount"> {{blacklist.week}}</div>
                            <div style="text-align: end;"> <div class="status-container"> ^ 100% </div> {{$t('dashboard.from', {text: 'this week'})}} </div>
                        </div> 
                        <div class="col-6 custom flex">
                            <span> {{$t('dashboard.this', {text: 'Month'})}} </span>
                            <div class="amount"> {{blacklist.month}}</div>
                            <div style="text-align: end;"> <div class="status-container"> ^ 100% </div> {{$t('dashboard.from', {text: 'this month'})}} </div>
                        </div>
                        <div class="col-6 custom flex">
                            <span> {{$t('sites.invoice.total')}} </span>
                            <div class="amount"> {{blacklist.total}}</div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <!-- GRAPHS -->
        <div class="col-9"> <h4 class="section-heading"> {{$t('dashboard.graphs').toUpperCase()}} </h4> </div>
        <div class="row" style="justify-content: space-between;">
            <div class="col-xl-6">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header">
                        <h5 class="card-title">{{$t('dashboard.fuel_recovery')}}<span class="badge badge-light badge-style-light">{{$t('dashboard.this_year')}}</span></h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.payments.chartOptions" :series="charts.payments.series"></vue-apex-charts>
                    </div>
                </div>
                <!-- <div class="card">
                    <div class="card-body">

                    </div>
                </div> -->
            </div>

            <div class="col-xl-6">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header">
                        <h5 class="card-title"> Parking <span class="badge badge-light badge-style-light">{{$t('dashboard.this_year')}}</span></h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.parking.chartOptions" :series="charts.parking.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Potential Blacklist Savings -->
            <div class="col-xl-6">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header">
                        <h5 class="card-title"> {{$t('dashboard.potential_blacklist_savings').toUpperCase()}} 
                            <!-- <span class="badge badge-light badge-style-light">{{$t('dashboard.this_year')}}</span> -->
                        </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="line" :options="charts.potentialBlacklist.chartOptions" :series="charts.potentialBlacklist.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Claims Reported Chart -->
            <div class="col-xl-6">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header">
                        <h5 class="card-title"> {{$t('dashboard.claims_reported').toUpperCase()}} 
                            <!-- <span class="badge badge-light badge-style-light">{{$t('dashboard.this_year')}}</span> -->
                        </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="line" :options="charts.claimsReported.chartOptions" :series="charts.claimsReported.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Blacklisted Vehicle Chart -->
            <div class="col-xl-6">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header">
                        <h5 class="card-title"> {{$t('dashboard.blacklisted_vehicle').toUpperCase()}} 
                            <!-- <span class="badge badge-light badge-style-light">{{$t('dashboard.this_year')}}</span> -->
                        </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.blacklistVehicle.chartOptions" :series="charts.blacklistVehicle.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Cancellations Chart -->
            <div class="col-xl-6">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header">
                        <h5 class="card-title"> {{$t('dashboard.cancellations_per_month').toUpperCase()}} 
                            <!-- <span class="badge badge-light badge-style-light">{{$t('dashboard.this_year')}}</span> -->
                        </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.cancellations.chartOptions" :series="charts.cancellations.series"></vue-apex-charts>
                    </div>
                </div>
            </div>
        </div>


    </div>


</template>

<script>
import { object } from 'yup/lib/locale';
// import { response } from 'express';
import dashboardService from '../../../services/dashboard.services';
import clientService from '../../../services/client.service';
import siteService from '../../../services/site.service';
import Loader from './Loader.vue';
import VueApexCharts from 'vue3-apexcharts';
import {DateTime} from 'luxon';

export default {
    name: 'Standard',
    components: {
        Loader,
        VueApexCharts
    },
    data() {
        return {
            loading: true,
            sites: [],
            selectedSite: -1,
            stats: {
                loading: true,
                paid: {
                    today: 0,
                    yesterday: 0
                },
                lost: {
                    today: 0,
                    yesterday: 0
                },
                pump: {
                    lost: 0,
                    pump: 0
                },
                site: {
                    lost: 0,
                    site: {
                        id: -1,
                        name: 'No Site'
                    }
                }
            },
            claims: {
                driveOff: 0,
                nmop: 0,
                parking: 0,
                last_month: 0,
                current_month: 0,
                money_lost: 0,
                money_reclaimed: 0,
                parking_last_month: 0,
                parking_total: 0,
                parking_curr_month: 0,
                parking_sites: 0,
                paid_on_site_total: 0,
                paid_on_site_last: 0,
                paid_on_site_current: 0
            },
            blacklist: {
                today: 0,
                yesterday: 0,
                week: 0,
                last_week: 0,
                month: 0,
                last_month: 0,
                total: 0,
                national: 0
            },
            events: {},
            previousYear: {
                moneyLost: false,
                parking: false,
                recovery: false
            },
            statusContainerClass: {
                success: false,
                danger: false
            },
            charts: {
                // Payments chart 
                payments: {
                    chartOptions: {
                        chart: {
                            id: 'vuechart-payments'
                        },
                        xaxis: {
                            type: 'datetime',
                            tickPlacement: 'off',
                            labels: {
                                formatter: function(timestamp) {
                                    return DateTime.fromMillis(timestamp).toFormat('MMM');
                                }
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded',
                                borderRadius: 10
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        }
                    },
                    series: []
                },
                parking: {
                    chartOptions: {
                        chart: {
                            id: 'vuechart-parking'
                        },
                        xaxis: {
                            type: 'datetime',
                            tickPlacement: 'between',
                            labels: {
                                formatter: function(timestamp) {
                                    return DateTime.fromMillis(timestamp).toFormat('MMM yyyy');
                                }
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded',
                                borderRadius: 10
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        }
                    },
                    series: []
                },
                potentialBlacklist: {
                    chartOptions: {
                        chart: {
                            id: 'vuechart-potentialBlacklist',
                            type: 'line',
                            height: 350
                        },
                        xaxis: {
                            type: 'datetime',
                            tickPlacement: 'off',
                            labels: {
                                formatter: function(timestamp) {
                                    return DateTime.fromMillis(timestamp).toFormat('MMM yyyy')
                                }
                            }
                        },
                        stroke: {
                            curve: 'straight'
                        },
                        markers: {
                            size: 5
                        },
                        dataLabels: {
                            style: {
                               
                            }
                        }
                    },
                    series: [],
                },
                claimsReported: {
                    chartOptions: {
                        chart: {
                            id: 'vuechart-claimsReported',
                            type: 'line'
                        },
                        xaxis: {
                            type: 'datetime',
                            tickPlacement: 'off',
                            labels: {
                                formatter: function(timestamp) {
                                    return DateTime.fromMillis(timestamp).toFormat('MMM yyyy')
                                }
                            }
                        },
                        stroke: {
                            curve: 'straight'
                        },
                        markers: {
                            size: 5
                        }
                    },
                    series: []
                },
                blacklistVehicle: {
                    chartOptions: {
                        chart: {
                            id: 'vuechart-blacklistVehicle',
                            type: 'bar'
                        },
                        xaxis: {
                            type: 'datetime',
                            tickPlacement: 'off',
                            labels: {
                                formatter: function(timestamp) {
                                    return DateTime.fromMillis(timestamp).toFormat('MMM');
                                }
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        }
                    },
                    series: []
                },
                cancellations: {
                    chartOptions: {
                        chart: {
                            id: 'vuechart-cancellations',
                            type: 'line'
                        },
                        xaxis: {
                            type: 'datetime',
                            tickPlacement: 'off',
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMM yyyy')
                            }
                        },
                        stroke: {
                            curve: 'straight'
                        },
                        markers: {
                            size: 5
                        }
                    },
                    series: []
                }
            }
        }
    },
    mounted() {
        this.loading = true;
        clientService.getClientSites(this.$store.state.auth.user.selectedClient, false)
        .then(response => {
            this.sites = response.data.sites;
            this.loading = false;
        })
        .catch(error => {
            this.$error(this.$t('sites.site_load_error'), error);
            this.loading = false;
        })
        this.loadData();
        // this.fuelRecoveryChart();
        this.parkingRevenueChart();
    },
    methods: {
        loadData(site) { 
            dashboardService.getDashboardData(this.$store.state.auth.user.selectedClient, site)
            .then(response => {
                for(let key in response.data.claims) {
                    this.claims[key] = parseInt(response.data.claims[key] || 0);
                }
                // this.claims = response.data.claims;
                this.blacklist = response.data.blacklist;
                this.events = response.data.events;
            })
            this.todaysData();
            this.fuelRecoveryChart();
            // this.parkingRevenueChart();
            this.potentialBlacklistChart();
            this.claimsReportedChart();
            this.blacklistedVehicleChart();
            this.cancellationsChart();
        },
        loadSiteDashboard() {
            this.loadData(this.selectedSite);
        },
        todaysData() {
            this.loading = true;
            dashboardService.getTodayStats(this.$store.state.auth.user.selectedClient, this.selectedSite)
            .then(response => {
                this.stats = response.data;
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
            })
        },
        toggleSwitch(obj) {
            this.previousYear[obj] = !this.previousYear[obj];
        },
        fuelRecoveryChart() {
            //Fuel recovery data for last 12 months 
            dashboardService.getRecoveryChartData(this.$store.state.auth.user.selectedClient, this.selectedClient).then(response => {
                let dates = [];
                let paid = [];
                let paid_onsite = [];

                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/ ${elem.year}`, 'M/yyyy').toFormat('MMM');
                    // console.log('standard month', month);
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    // console.log('standard' ,dates);
                    paid.push([DateTime.fromFormat(`${elem.month}/ ${elem.year}`, 'M/yyyy').toJSDate, elem.paid.toFixed(2)]);
                });

                response.data.other_data.forEach(elem => {
                    paid_onsite.push([DateTime.fromFormat(`${elem.month}/ ${elem.year}`, 'M/yyyy').toJSDate, elem.paid.toFixed(2)]);
                })

                this.charts.payments.series.push({name: this.$t('dashboard.money_recovered'), data: paid});
                this.charts.payments.series.push({name: this.$t('dashboard.recovered_on_site'), data: paid_onsite});
            })
            .catch(error => {
            
            })

            //Fuel lost for past 12 months 
            dashboardService.getDriveoffReportedChartData(this.$store.state.auth.user.selectedClient, this.selectedClient).then(response => {
                let dates = [];
                let paid = [];
                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)){
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(),parseFloat(elem.paid).toFixed(2)]);
                })
                this.charts.payments.series.push({name: this.$t('dashboard.losses_reported'), data: paid});
            })
            .catch(error => {

            })
        },
        parkingRevenueChart() {
            //chart starts from current month + 1 previous year 
            //chart ends at current month current year 
            /**
             * Data required - 
             * 1. Money paid for parking 
             * 2. Corresponding month 
             * 3. Money paid for given month's previous year 
             */

            dashboardService.getParkingChartData(this.$store.state.auth.user.selectedClient, this.selectedSite)
            .then(response => {
                let dates = [];
                let paid = [];

                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate() ,parseFloat(elem.paid).toFixed(2)]);
                });

                // this.charts.parking.chartOptions.xaxis.categories = dates;
                // console.log(this.charts.parking.chartOptions.xaxis.categories)

                this.charts.parking.series.push({name: this.$t('dashboard.parking_revenue'), data: paid});
            })
        },
        potentialBlacklistChart() {
            dashboardService.getPotentialBlacklistChartData(this.$store.state.auth.user.selectedClient, this.selectedSite)
            .then(response => {
                let dates = [];
                let paid = [];
                let count = 0;
                response.data.data.forEach(elem => {
                    // let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    // if(!dates.includes(month)) {
                    //     dates.push(month);
                    // }
                    
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), count]);
                    count += 10;
                    // paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                    
                });
                this.charts.potentialBlacklist.series.push({name: this.$t('dashboard.potential_blacklist_savings'), data: paid});
            })
        },
        claimsReportedChart() {
            dashboardService.getDriveoffReportedChartData(this.$store.state.auth.user.selectedClient, this.selectedSite)
            .then(response => {
                this.charts.claimsReported.series.push({name: this.$t('dashboard.claims_reported'), data: this.getPaid(response.data.data)})
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
            })
        },
        blacklistedVehicleChart() {
            dashboardService.getBlacklistedVehicleChartData(this.$store.state.auth.user.selectedClient, this.selectedSite)
            .then(response => {
                this.charts.blacklistVehicle.series.push({name: this.$t('dashboard.blacklisted_vehicle'), data: this.getPaid(response.data.data)});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
            })
        },
        cancellationsChart() {
            dashboardService.getCancellationsChartData(this.$store.state.auth.user.selectedClient, this.selectedSite)
            .then(response => {
                // console.log(response.data);
                this.charts.cancellations.series.push({name: this.$t('dashboard.blacklisted_vehicle'), data: this.getPaid(response.data.data)});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
            })
        },
        getPaid(data) {
            let paid = [];
            let count = 0;
            data.forEach(elem => {
                // paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), count]);
                count += 10;
            });
            return paid;
        },
        openTodaysReport(status) {
            if(status === 'lost') {
                //TODO - What to pass in params for today's losses 
            }
            const params = {
                start_date: DateTime.local().endOf('day').toFormat('yyyy-MM-dd'),
                status: status,
                site: this.selectedSite === -1 ? null : this.selectedClient 
            }
            console.log(params);
            this.$router.push({name: 'reports', params: params});
        },
        openProcessingClaims(type) {
            this.$router.push({name: 'reports', params: {type: type, site: this.selectedSite === -1 ? null : this.selectedSite}});
        }
    },
    computed: {
        selected_client: function() {
            return this.$store.state.auth.user.clients.filter(client => {
                return parseInt(client.id) === parseInt(this.$store.state.auth.user.selectedClient)
            })[0]
        },
        paid_percentage: function() {
            let difference = this.stats.paid.today - this.stats.paid.yesterday;
        
            if(this.stats.paid.yesterday !== 0 && this.stats.paid.yesterday !== null) {
                if(difference < 0) {
                    return {icon: 'keyboard_arrow_down', value: Math.abs(difference /this.stats.paid.yesterday * 100).toFixed(2)}
                } else {
                    return {icon: 'keyboard_arrow_up', value: Math.abs(difference /this.stats.paid.yesterday * 100).toFixed(2)}
                }
            } else {
                //if nothing was paid yesterday then it is a 100% increase in today's payment
                return {icon: 'keyboard_arrow_up', value: 100}
            }
        },
        lost_percentage: function() {
            let difference = this.stats.lost.today - this.stats.lost.yesterday;

            if(this.stats.lost.yesterday !== 0 && this.stats.lost.yesterday !== null) {
                if(difference < 0) {
                    return {icon: 'keyboard_arrow_down', value: Math.abs(difference / this.stats.lost.yesterday * 100).toFixed(2)};
                } else{
                    return {icon: 'keyboard_arrow_up', value: Math.abs(difference / this.stats.lost.yesterday * 100).toFixed(2)};
                }
            } else {
                return {icon: 'keyboard_arrow_up', value: 100}
            }
        },
        currency: function() {
            //selected_client.locale does not changes value on changing countries
            return this.$i18n.locale === 'en-GB' ? 'currency_pound' : 'euro'
        },
        total_claims: function() {
            return (this.claims.driveOff + this.claims.nmop + this.claims.parking)
        }
    }
}
</script>

<style scoped> 
    .temp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 50vh;
    }

    .main-card {
        background-color: rgba(31, 31, 43, .3);
    }

    .widget-stats .widget-stats-container .widget-stats-content .widget-stats-title {
        font-size: 14px;
        color: white;
        font-weight: 400;
    }

    .widget-stats .widget-stats-container .widget-stats-indicator {
        font-size: 14px;
        padding: 5px;
    }

    .grid {
        display: grid;
        grid-template-columns: 24% 24% 24% 24%;
        gap: 1em;
    }

    tr {
        text-align: center;
    }

    .temp2 {
        background-color: #6939CE;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        position: relative;
    }

    .temp2 span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: 500;
    }

    .flex-center {
        flex-direction: column;
        padding: 25px 10px;
    }

    .todays-data:hover, .col-6.custom:hover {
        cursor: pointer;
        box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
    }

    .recovery-stats {
        background-color: #4BAD48;
        padding: 25px 50px; 
        width: fit-content; 
        border-radius: 10px;
    }

    .recovery-stats span {
        font-size: 25px;
        font-weight: bold;
    }

    .flex-center .card-title h5 {
        margin: 0;
    }

    .col-6.custom {
        width: 46%;
        background-color: #313144; 
        margin: 10px;
        padding: 20px;
        border-radius: 10px;
        flex-direction: column;
        /* align-items: unset; */
    }

    .amount {
        font-size: 25px;
        font-weight: 600;
    }

    .status-container {
        padding: 0 9px;
        border-radius: 5px;
        font-weight: 500;
        align-items: center;
        display: inline-block;
        vertical-align: middle;
    }

    .success {
        background-color: rgba(75, 173, 72, .25);
        color: var(--utility-green);
    }

    .danger {
        background-color: rgba(220, 53, 69, .25);
        color: var(--utility-red);
    }

    /* @media (min-width: 1200px) {
        .col-xl-6 {
            width: 48%;
            justify-content: space-between;
        }
    } */

</style>