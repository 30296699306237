<template>
    <div class="board" :id="id" @dragover.prevent @drop.prevent="drop">
        <slot/>
    </div>
  
</template>

<script>
export default {
    name: 'Board',
    props: {
        id: Number
    },
    methods: {
        drop(event) {
            //get the card_id of the card that's being dragged 
            const card_id = event.dataTransfer.getData('card_id');
            console.log(card_id);
            // store the card 
            const card = document.getElementById(card_id);
            console.log(card);
            // when dragging the element hides, so when the element drops it needs to be displayed
            card.style.display = "flex";
            event.target.appendChild(card);
        }
    }
}
</script>

<style>

</style>