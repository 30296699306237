<template>
    <div class="wrapper" v-show="loading">
        <div class="sub-wrapper">
            <Loader/>
        </div>
    </div>
    <div v-if="gateway_timeout > 5" class="alert alert-custom alert-indicator-left indicator-danger" style="margin-top: 20px" role="alert">
        <div class="alert-content">
            <span class="alert-text" style="font-size: large;">
                There appears to be an issue with our services, we are trying to resolve the issue as quickly as possible.
            </span>
        </div>
    </div>
    <div class="" v-if="!loading">
        <div class="row">
            <!-- Select site group -->
            <div v-if="siteGroups.length > 0" class="col-xl-3 p-v-sm m-b-md">
                <div class="form-group">
                    <label for="selectSite" class="form-label"> Select Site Group </label>
                    <Multiselect
                        v-model="siteGroup"
                        :class="{'multi-dark': $store.state.auth.status.dark}"
                        mode="single"
                        placeholder="Select a site group"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="true"
                        :multiple="false"
                        :options="siteGroups"
                        label="name"
                        track-by="id"
                        name="sitegroup"
                        @select="(value) => loadSiteGroupData(value)"
                        @remove="removeSiteGroup"
                    />
                </div> 
            </div>
            <div class="col-4 p-v-sm m-b-md">
                <div class="form-group">
                    <label for="selectSite" class="form-label"> {{$t('dashboard.select_site')}} </label>
                    <div class="row" style="display: flex;">
                        <div class="col-12 col-md-8" style="display: inline-block;">
                            <Multiselect
                                v-model="site"
                                :class="{'multi-dark': $store.state.auth.status.dark}"
                                mode="single"
                                placeholder="Select a site"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="true"
                                :multiple="false"
                                :options="sites"
                                label="name"
                                track-by="id"
                                name="site"
                                @select="(value) => loadData(value)"
                                @remove="removeSite"
                            />
                        </div>
                        <div class="col-6 col-md-4" style="display: inline-block;">
                            <button class="btn btn-primary space-between" v-show="selectedSite > 0" @click="resetSiteFilter()">Show all Sites</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       <!-- Today's data -->
       <div class="row" id="todays-payments" draggable="true" @dragstart="dragStart" @dragover.stop>
        <h4 class="section-heading m-b-sm"> {{$t(data_heading.heading).toUpperCase()}} </h4>
        <div class="form-group col-xl-3 m-b-sm form-heading">
           <select v-model="selectedTime" class="form-select" @change="() => todaysData()">
               <option value="day" selected> {{$t('dashboard.todays_data')}} </option>
               <option value="week"> {{$t('dashboard.weeks_data')}}</option>
               <option value="month"> {{$t('dashboard.months_data')}}</option>
               <option value="year"> {{$t('dashboard.years_data')}}</option>
               <option value="lastyear">{{ $t('dashboard.last_years_data') }}</option>
           </select>
        </div>
        <div v-if="loadingTodaysData" style="width: 100%; text-align: center;">
            <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                <span class="visually-hidden">{{ $t('events.loading') }}...</span>
            </div>
        </div>
        <div v-else class="row">
            <!-- Today's payments -->
            <div class="col-xl-6" @click="openTodaysReport({status: 'Paid'})">
                <div class="card widget widget-stats todays-data" :title="projectedRecovery">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-success">
                                    <i class="material-icons-outlined"> {{currency}} </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <span class="widget-stats-title"> {{$t('dashboard.todays-payments')}} </span>
                                <span class="widget-stats-amount">
                                    <!-- <span v-if="selected_client.locale === 'en-GB'"> £ </span>
                                    <span v-else> € </span> -->
                                    <span> {{currency === 'currency_pound' ? '£' : '€'}} </span>
                                    <span> {{parseFloat(stats.paid.today || 0).toLocaleString()}} <span v-if="selectedTime == 'month'" style="font-size: 16px;">(+{{currency === 'currency_pound' ? '£' : '€'}}{{ parseFloat(claims?.current?.paid_on_site_current || 0).toLocaleString() }}<span v-if="$can('claims-process')"> / {{ paidOnSitePerc }}%</span> on site<span class="text-danger">*</span>)</span>
                                    <span v-if="selectedTime == 'year'" style="font-size: 16px;">(+{{currency === 'currency_pound' ? '£' : '€'}}{{ parseFloat(claims?.current?.paid_on_site_year || 0).toLocaleString() }}<span v-if="$can('claims-process')"> / {{ paidOnSitePerc }}%</span> on site<span class="text-danger">*</span>)</span> </span>
                                </span>
                                <span class="widget-stats-info"> {{currency === 'currency_pound' ? '£' : '€'}} {{parseFloat(stats.paid.yesterday || 0).toLocaleString()}} {{$t(data_heading.day).toLowerCase()}} &nbsp; &nbsp; <span><span class="text-danger">*</span> Charges may apply to claims paid on site</span> </span>
                            </div>
                            <div class="widget-stats-indicator align-self-start" :class="paid_percentage.icon === 'keyboard_arrow_up' ? 'widget-stats-indicator-positive' : 'widget-stats-indicator-negative'">
                                <i class="material-icons"> {{paid_percentage.icon}} </i>
                                {{paid_percentage.value}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Today's losses -->
            <div class="col-xl-6"  @click="openTodaysReport({status: 'outstanding'})"> 
                <div class="card widget widget-stats todays-data" :title="projectedLosses">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-danger">
                                    <i class="material-icons-outlined"> {{currency}} </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <span class="widget-stats-title"> {{$t('dashboard.todays-losses')}} </span>
                                <span class="widget-stats-amount">
                                    <!-- <span v-if="selected_client.locale === 'en-GB'"> £ </span>
                                    <span v-else> € </span> -->
                                    <span> {{currency === 'currency_pound' ? '£' : '€'}}</span>
                                    <span> {{parseFloat(stats.lost.today || 0).toLocaleString()}} </span>
                                </span>
                                <span class="widget-stats-info"> {{currency === 'currency_pound' ? '£' : '€'}} {{parseFloat(stats.lost.yesterday || 0).toLocaleString()}} {{$t(data_heading.day).toLowerCase()}} </span>
                            </div>
                            <div class="widget-stats-indicator align-self-start" :class="lost_percentage.icon === 'keyboard_arrow_up' ? 'widget-stats-indicator-negative' : 'widget-stats-indicator-positive'">
                                <i class="material-icons"> {{lost_percentage.icon}} </i>
                                {{lost_percentage.value}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Worst Site -->
            <div v-if="stats.site !== undefined" class="col-xl-12" @click="openTodaysReport({site: stats.site.site.id})">
                <div class="card widget widget-stats todays-data">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-warning">
                                    <i class="material-icons-outlined"> car_crash </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <div v-show="sites.length > 1">
                                    <span class="widget-stats-title" style="display: inline;"> {{$t('dashboard.worst_site')}} </span>
                                    <button class="btn btn-sm btn-primary float-right" @click.stop="$router.push(`/worstsites?period=${selectedTime}`)">See Worst Sites</button>
                                    <!-- <i class="material-icons float-right desc-icon" title="View details" @click.stop="$router.push({name: 'worstsites'})"> description </i> -->
                                </div>
                                <span class="widget-stats-amount"> {{stats.site.site.name}} </span>
                                <span class="widget-stats-info"> {{currency === 'currency_pound' ? '£' : '€'}} {{parseFloat(stats.site.lost || 0).toLocaleString()}} lost on site </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Worst Pump -->
            <div v-if="stats.pump !== undefined" class="col-xl-12" @click="openTodaysReport({site: selectedSite})">
                <div class="card widget widget-stats todays-data">
                    <div class="card-body">
                        <div class="widget-stats-container d-flex">
                            <div style="margin: auto;">
                                <div class="widget-stats-icon widget-stats-icon-primary">
                                    <i class="material-icons-outlined"> local_gas_station </i>
                                </div>   
                            </div>
                            <div class="widget-stats-content flex-fill">
                                <span class="widget-stats-title"> {{$t('dashboard.worst_pump')}} </span>
                                <span class="widget-stats-amount"> {{stats.pump.pump === 0 ? 'No Pump' : stats.pump.pump}} </span>
                                <span class="widget-stats-info">£{{parseFloat(stats.pump.lost || 0).toLocaleString()}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
       </div>


       <h4 class="section-heading p-v-sm no-m"> TOTAL RECOVERY </h4>
       <div class="card">
            <div class="card-body row space-between">
                <div class="col-xl-6 recovery-stats" style="width: 49%">
                    <div style="font-weight: 500;"> Fuel Paid </div>
                    <div class="m-b-xs"> {{$t('sites.site.lifetime')}}</div>
                    <div class="figure"> £{{parseFloat(claims.current.money_reclaimed).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                </div>
                <div class="col-xl-6 recovery-stats" style="width: 49%">
                    <div style="font-weight: 500;"> Parking Paid </div>
                    <div class="m-b-xs"> {{$t('sites.site.lifetime')}}</div>
                    <div class="figure"> £{{parseFloat(claims.current.parking_total).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                </div>
            </div>
       </div>

        <div class="row">
            <!-- Recovery -->   
            <div class="col-xl-8" id="todays-recovery" draggable="true" @dragstart="dragStart" @dragover.stop>
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.recovery').toUpperCase()}} </h4>
                <div class="card dashboard">
                    <div class="card-body row space-between">
                        <!-- Last month -->
                        <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: datesObj.last_month.start_date, end_date: datesObj.last_month.end_date, site: selectedSite, group: selectedSiteGroup}})">  
                            <div style="font-weight: 500;"> {{previous_month}} </div>
                            <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Month'})}}</div>
                            <div class="figure"> £{{parseFloat(claims.current.last_month).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div>

                        <!-- This month -->
                        <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: datesObj.this_month.start_date, end_date: datesObj.this_month.end_date, site: selectedSite, group: selectedSiteGroup}})"  :title="projectedRecovery">
                            <div style="font-weight: 500;"> {{current_month}} </div>
                            <div class="m-b-xs" style="font-weight: 500;"> {{$t('dashboard.this', {text: 'Month'})}} </div>
                            <div class="figure"> £{{parseFloat(claims.current.current_month).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div>

                        <!-- This year -->
                        <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: datesObj.this_year.start_date, end_date: datesObj.this_year.end_date, site: selectedSite, group: selectedSiteGroup}})" :title="projectedRecoveryYear">
                            <div style="font-weight: 500;"> {{current_year}} </div>
                            <div class="m-b-xs" style="font-weight: 500;"> {{$t('dashboard.this', {text: 'Year'})}} </div>
                            <div class="figure"> £{{parseFloat(claims.current.current_year).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div>
                        <div class="dashboard center-text" @click="toggleTab('recovery')">
                            <span> {{$t('dashboard.previous_year_data')}} <i v-show="!tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_up </i> </span>
                        </div>
                        <div v-show="tabs.recovery" class="row space-between no-s">
                            <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: previousYear.last_month.start, end_date: previousYear.last_month.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{previous_month}} </div>
                                <div class="m-b-xs" style="font-weight: 500;"> {{previous_month_previous_year}} </div>
                                <div class="figure"> £{{parseFloat(claims.previous.fuel_previous_last).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                            </div>
                            <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: previousYear.month.start, end_date: previousYear.month.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{current_month}} </div>
                                <div class="m-b-xs" style="font-weight: 500;"> {{previous_year}} </div>
                                <div class="figure"> £{{parseFloat(claims.previous.fuel_previous_current).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                            </div>
                            <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: previousYear.year.start, end_date: previousYear.year.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{previous_year}} </div>
                                <div class="m-b-xs" style="font-weight: 500;"> {{$t('dashboard.last_year')}} </div>
                                <div class="figure"> £{{parseFloat(claims.previous.fuel_previous_year).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                            </div>
                            <div class="dashboard center-text" @click="toggleTab('recovery')">
                                <a href="#oldData">
                                    <span href="#oldData"> {{ "View older data" }} 
                                        <i v-show="!tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> 
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Money Lost -->
            <div class="col-xl-4" id="todays-loss" draggable="true" @dragstart="dragStart" @dragover.stop>
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.money_lost').toUpperCase()}} </h4>
                <div class="card dashboard">
                    <div class="card-body">
                        <!-- <div class="recovery-stats m-b-lg" style="width: 100%" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: datesObj.this_year.start_date, end_date: datesObj.this_year.end_date, site: selectedSite}})">
                            <div style="font-weight: 500;"> Paid </div>
                            <div class="m-b-xs"> {{$t('sites.site.lifetime')}}</div>
                            <div class="figure"> £{{parseFloat(claims.current.money_reclaimed).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div> -->
                        <div class="recovery-stats lost" @click="$router.push({name: 'reports', params: {status: 'outstanding', start_date: datesObj.this_year.start_date, end_date: datesObj.this_year.end_date, site: selectedSite, group: selectedSiteGroup}})">
                            <div style="font-weight: 500;"> {{$t('dashboard.outstanding')}} </div>
                            <div class="m-b-xs"> {{$t('sites.site.lifetime')}}</div>
                            <div class="figure"> £{{parseFloat(claims.current.money_lost).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div>
                        <!-- <div class="dashboard center-text" @click="toggleTab('lost')">
                            <span> {{$t('dashboard.previous_year_data')}} <i v-show="!tabs.lost" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.lost" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_up </i> </span>
                        </div> -->
                        <!-- <div v-show="tabs.lost" class="recovery-stats lost" @click="$router.push({name: 'reports', params: {status: 'outstanding', start_date: previousYear.year.start, end_date: previousYear.year.end, site: selectedSite}})">
                            <div style="font-weight: 500;"> {{$t('dashboard.outstanding')}} </div>
                            <div class="m-b-xs"> {{previous_year}}</div>
                            <div class="figure"> £{{parseFloat(claims.previous.money_lost).toLocaleString() || 0}} </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="card">
                <div id="previousYearRecovery" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#previousYearRecovery" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#previousYearRecovery" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div> -->

        <div class="row">
            <!-- Recovery in liters -->
            <div class="col-xl-8" id="recovery-liters" draggable="true" @dragstart="dragStart" @dragover.stop>
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.recovery_of_fuel').toUpperCase()}} </h4>
                <div class="card dashboard">
                    <div class="card-body row space-between">
                        <!-- Last month -->
                        <div class="col-xl-4 recovery-stats fuel-recovery-card" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: datesObj.last_month.start_date, end_date: datesObj.last_month.end_date, site: selectedSite, group: selectedSiteGroup}})">
                            <div style="font-weight: 500;"> {{previous_month}} </div>
                            <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Month'})}}</div>
                            <div class="figure"> {{parseFloat(claims.current.litres_recovered_last).toLocaleString() || 0}}L </div>
                        </div>

                        <!-- This month -->
                        <div class="col-xl-4 recovery-stats fuel-recovery-card" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: datesObj.this_month.start_date, end_date: datesObj.this_month.end_date, site: selectedSite, group: selectedSiteGroup}})">
                            <div style="font-weight: 500;"> {{current_month}} </div>
                            <div class="m-b-xs" style="font-weight: 500;"> {{$t('dashboard.this', {text: 'Month'})}} </div>
                            <div class="figure"> {{parseFloat(claims.current.litres_recovered_current).toLocaleString() || 0}}L </div>
                        </div>

                        <!-- This year -->
                        <div class="col-xl-4 recovery-stats fuel-recovery-card" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: datesObj.this_year.start_date, end_date: datesObj.this_year.end_date, site: selectedSite, group: selectedSiteGroup}})">
                            <div style="font-weight: 500;"> {{current_year}} </div>
                            <div class="m-b-xs" style="font-weight: 500;"> {{$t('dashboard.this', {text: 'Year'})}} </div>
                            <div class="figure"> {{parseFloat(claims.current.litres_recovered_year).toLocaleString() || 0}}L </div>
                        </div>
                        <div class="dashboard center-text" @click="toggleTab('fuel_recovery')">
                            <span> {{$t('dashboard.previous_year_data')}} <i v-show="!tabs.fuel_recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.fuel_recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_up </i> </span>
                        </div>
                        <div v-show="tabs.fuel_recovery" class="row space-between no-s">
                            <div class="col-xl-4 recovery-stats fuel-recovery-card" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: previousYear.last_month.start, end_date: previousYear.last_month.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{previous_month}} </div>
                                <div class="m-b-xs" style="font-weight: 500;"> {{previous_year}} </div>
                                <div class="figure"> {{parseFloat(claims.previous.litres_previous_last).toLocaleString() || 0}}L </div>
                            </div>
                            <div class="col-xl-4 recovery-stats fuel-recovery-card" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: previousYear.month.start, end_date: previousYear.month.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{current_month}} </div>
                                <div class="m-b-xs" style="font-weight: 500;"> {{previous_year}} </div>
                                <div class="figure"> {{parseFloat(claims.previous.litres_previous_current).toLocaleString() || 0}}L </div>
                            </div>
                            <div class="col-xl-4 recovery-stats fuel-recovery-card" @click="$router.push({name: 'reports', params: {status: 'Paid', start_date: previousYear.year.start, end_date: previousYear.year.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{previous_year}} </div>
                                <div class="m-b-xs" style="font-weight: 500;"> {{$t('dashboard.last_year')}} </div>
                                <div class="figure"> {{parseFloat(claims.previous.litres_previous_year).toLocaleString() || 0}}L </div>
                            </div>
                            <div class="dashboard center-text" @click="toggleTab('recovery')">
                                <a href="#oldData">
                                    <span href="#oldData"> {{ "View older data" }} 
                                        <i v-show="!tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> 
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Loss in liters -->
            <div class="col-xl-4" id="loss-liters" draggable="true" @dragstart="dragStart" @dragover.stop>
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.fuel_lost').toUpperCase()}} </h4>
                <div class="card dashboard">
                    <div class="card-body row space-between">
                        <div class="recovery-stats lost" @click="$router.push({name: 'reports', params: {status: 'outstanding', start_date: datesObj.this_year.start_date, end_date: datesObj.this_year.end_date, site: selectedSite, group: selectedSiteGroup}})">
                            <div style="font-weight: 500;"> {{$t('dashboard.outstanding')}} </div>
                            <div class="m-b-xs"> {{$t('sites.site.lifetime')}}</div>
                            <div class="figure"> {{parseFloat(claims.current.litres_outstanding).toLocaleString() || 0}}L </div>
                        </div>
                        <!-- <div class="dashboard center-text" @click="toggleTab('fuel_lost')">
                            <span> {{$t('dashboard.previous_year_data')}} <i v-show="!tabs.fuel_lost" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.fuel_lost" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_up </i> </span>
                        </div>
                        <div v-show="tabs.fuel_lost" class="recovery-stats lost" @click="$router.push({name: 'reports', params: {status: 'outstanding', start_date: previousYear.year.start, end_date: previousYear.year.end, site: selectedSite}})">
                            <div style="font-weight: 500;"> {{$t('dashboard.outstanding')}} </div>
                            <div class="m-b-xs"> {{previous_year}}</div>
                            <div class="figure"> {{parseFloat(fuelRecovery.fuel_lost_previous).toLocaleString() || 0}}L </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div v-if="claims.current.parking_sites > 0" class="row">
            <!-- Parking -->
            <div class="col-xl-8" id="parking" draggable="true" @dragstart="dragStart" @dragover.stop>
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.parking').toUpperCase()}} </h4>
                <div class="card dashboard">
                    <div class="card-body row space-between">
                        <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {type: 'PARKING', start_date: datesObj.last_month.start_date, end_date: datesObj.last_month.end_date, site: selectedSite, group: selectedSiteGroup}})">
                            <div style="font-weight: 500;"> {{previous_month}} </div>
                            <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Month'})}}</div>
                            <div class="figure"> £{{parseFloat(claims.current.parking_last_month).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div>
                        <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {type: 'PARKING', start_date: datesObj.this_month.start_date, end_date: datesObj.this_month.end_date, site: selectedSite, group: selectedSiteGroup}})" :title="projectedParking">
                            <div style="font-weight: 500;"> {{current_month}} </div>
                            <div class="m-b-xs"> {{$t('dashboard.this', {text: 'Month'})}}</div>
                            <div class="figure"> £{{parseFloat(claims.current.parking_curr_month).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div>
                    
                        <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {type: 'PARKING', start_date: datesObj.this_year.start_date, end_date: datesObj.this_year.end_date, site: selectedSite, group: selectedSiteGroup}})" :title="projectedParkingYear">
                            <div style="font-weight: 500;"> {{current_year}} </div>
                            <div class="m-b-xs" style="font-weight: 500;"> {{$t('dashboard.this', {text: 'Year'})}} </div>
                            <div class="figure"> £{{parseFloat(claims.current.parking_curr_year).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                        </div>
                        <div class="dashboard center-text" @click="toggleTab('parking')">
                            <span> {{$t('dashboard.previous_year_data')}} <i v-show="!tabs.parking" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.parking" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_up </i> </span>
                        </div>

                        <div v-show="tabs.parking" class="row space-between no-s">
                            <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {type: 'PARKING', start_date: previousYear.last_month.start, end_date: previousYear.last_month.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{previous_month}} </div>
                                <div class="m-b-xs"> {{previous_year}}</div>
                                <div class="figure"> £{{parseFloat(claims.previous.parking_paid_last).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                            </div>
                            <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {type: 'PARKING', start_date: previousYear.month.start, end_date: previousYear.month.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{current_month}} </div>
                                <div class="m-b-xs"> {{previous_year}}</div>
                                <div class="figure"> £{{parseFloat(claims.previous.parking_paid_current).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                            </div>
                            <div class="col-xl-4 recovery-stats" @click="$router.push({name: 'reports', params: {type: 'PARKING', start_date: previousYear.year.start, end_date: previousYear.year.end, site: selectedSite, group: selectedSiteGroup}})">
                                <div style="font-weight: 500;"> {{previous_year}} </div>
                                <div class="m-b-xs"> {{$t('dashboard.last', {text: 'Year'})}}</div>
                                <div class="figure"> £{{parseFloat(claims.previous.parking_paid_year).toLocaleString(undefined, {minimumFractionDigits: 2}) || 0}} </div>
                            </div>
                            <div class="dashboard center-text" @click="toggleTab('recovery')">
                                <a href="#oldData">
                                    <span href="#oldData"> {{ "View older data" }} 
                                        <i v-show="!tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> <i v-show="tabs.recovery" class="material-icons-outlined" style="vertical-align: text-top;"> keyboard_arrow_down </i> 
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Active Sites -->
            <div class="col-xl-4" id="active-sites" draggable="true" @dragstart="dragStart" @dragover.stop>
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.active_parking_sites').toUpperCase()}} </h4>
                <div class="card dashboard">
                    <div class="card-body row space-between" style="padding-bottom: 70px;">
                        <div class="recovery-stats active-sites">
                            <div > {{current_year}} </div>
                            <div class="m-b-xs" style="font-weight: 500;"> &nbsp; </div>
                            <div class="figure"> {{claims.current.parking_sites}} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showBlacklist" class="row">
            <!-- Blacklist -->
            <div class="col-xl-12" id="blacklist" draggable="true" @dragstart="dragStart" @dragover.stop>
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.blacklist').toUpperCase()}} </h4>
                <div class="card">
                    <div class="row space-between card-body">
                        <div class="col-3 bl-card-body" @click="$router.push({name: 'eventsNew', params: {status: 'blacklisted',start_date: dates.today.start, end_date: dates.today.end, start_time: '00:00', end_time: '23:59', site: JSON.stringify(site)}})">
                           <span> {{$t('dashboard.blacklist_today')}} </span>
                            <div class="amount"> {{blacklist.today.toLocaleString()}}</div>
                            <div class="widget-stats-indicator align-self-start" style="float: right;" :class="blacklistPercIndicator(blacklistTodayChange.icon)">
                                <i class="material-icons" style="vertical-align: middle;"> {{blacklistTodayChange.icon}} </i>
                                {{blacklistTodayChange.value}}% <span style="font-size: 12px;"> {{$t('dashboard.from', {text: 'yesterday'})}} </span>
                            </div>
                        </div>
                        <div class="col-3 bl-card-body" @click="$router.push({name: 'eventsNew', params: {status: 'blacklisted', start_date: datesObj.this_week.start_date, end_date: datesObj.this_week.end_date, site: JSON.stringify(site)}})">
                            <span> {{$t('dashboard.blacklist_week', {text: 'Week'})}} </span>
                            <div class="amount"> {{blacklist.week.toLocaleString()}}</div> 
                            <div class="widget-stats-indicator align-self-start" style="float: right;" :class="blacklistPercIndicator(blacklistWeekChange.icon)">
                                <i class="material-icons" style="vertical-align: middle;"> {{blacklistWeekChange.icon}} </i>
                                {{blacklistWeekChange.value}}% <span style="font-size: 12px;"> {{$t('dashboard.from', {text: 'last week'})}} </span>
                            </div>
                        </div>
                        <div class="col-3 bl-card-body" @click="$router.push({name: 'eventsNew', params: {status: 'blacklisted', start_date: datesObj.this_month.start_date, end_date: datesObj.this_month.end_date, site: JSON.stringify(site)}})">
                            <span> {{$t('dashboard.blacklist_month', {text: 'Month'})}} </span>
                            <div class="amount"> {{blacklist.month.toLocaleString()}}</div>
                            <div class="widget-stats-indicator align-self-start" style="float: right;" :class="blacklistPercIndicator(blacklistMonthChange.icon)">
                                <i class="material-icons" style="vertical-align: middle;"> {{blacklistMonthChange.icon}} </i>
                                {{blacklistMonthChange.value}}% <span style="font-size: 12px;"> {{$t('dashboard.from', {text: 'last month'})}} </span>
                            </div>    
                        </div>
                        <div class="col-3 bl-card-body" @click="$router.push({name: 'eventsNew', params: {status: 'blacklisted', start_date: datesObj.this_year.start_date, end_date: datesObj.this_year.end_date, site: JSON.stringify(site)}})">
                            <span> {{$t('dashboard.blacklist_year', {text: 'Year'})}} </span>
                            <div class="amount"> {{blacklist.year.toLocaleString()}}</div>
                            <div class="widget-stats-indicator align-self-start" style="float: right;" :class="blacklistPercIndicator(blacklistYearChange.icon)">
                                <i class="material-icons" style="vertical-align: middle;"> {{blacklistYearChange.icon}} </i>
                                {{blacklistYearChange.value}}% <span style="font-size: 12px;"> {{$t('dashboard.from', {text: 'last year'})}} </span>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div v-if="showBlacklist" class="row">
            <!-- Blacklist -->
            <div class="col-xl-12" id="blacklist">
                <div class="card">
                    <div class="row space-between card-body">
                        <div class="col-12 bl-card-body" style="width: 100%;" @click="$router.push({name: 'blacklist'})">
                            <span> {{$t('dashboard.blacklist_total')}} </span>
                            <div class="amount"> {{blacklist.total.toLocaleString()}} <span class="text-success">+ {{blacklist.national.toLocaleString()}} Nationally</span></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Currently processing -->
        <h4 class="section-heading p-v-sm"> {{$t('dashboard.currently_processing').toUpperCase()}} </h4>
        <!-- Currently processing -->
        <!-- <h4 class="section-heading p-v-sm"> {{$t('dashboard.currently_processing').toUpperCase()}} </h4> -->
        <div class="row">
            <div class="col-xl-12">

                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="card widget widget-stats-large">
                                <div class="row">
                                    <div class="col-xl-8">
                                        <div class="widget-stats-large-chart-container">
                                            <div class="card-header">
                                                <h5 class="card-title">{{$t('navigation.side.claims')}}<span class="badge badge-light badge-style-light">{{$t('sites.site.lifetime')}}</span></h5>
                                            </div>
                                            <div class="card-body" style="position: relative;">
                                                <vue-apex-charts type="bar" :options="charts.claims.chartOptions" :series="charts.claims.series"></vue-apex-charts>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="widget-stats-large-info-container">
                                            <div class="card-header">
                                                <h5 class="card-title">{{$t('dashboard.overview')}}</h5>
                                            </div>
                                            <div class="card-body">
                                                <p class="card-description text-muted">{{$t('dashboard.chart_description')}}</p>
                                                <ul class="list-group list-group-flush text-light">
                                                    <li class="list-group-item" @click="openReports('driveoff')">{{$t('global.driveoff')}}<span class="float-end">{{claims.current.driveoff.toLocaleString()}}</span></li>
                                                    <li class="list-group-item" @click="openReports('nmop')">{{$t('global.nmop')}}<span class="float-end">{{claims.current.nmop.toLocaleString()}}</span></li>
                                                    <li class="list-group-item" @click="openReports('parking')">{{$t('global.parking')}}<span class="float-end">{{claims.current.parking.toLocaleString()}}</span></li>
                                                    <li class="list-group-item" @click="openReports('cnaf')">{{$t('global.cnaf')}}<span class="float-end">{{claims.current?.cnaf?.toLocaleString() || 0}}</span></li>
                                                    <li class="list-group-item" @click="openReports('')">{{$t('sites.invoice.total')}} <span class="float-end"> {{ total_claims }}</span></li>
                                                    <!-- <li class="list-group-item">{{$t('dashboard.being_recovered')}}<span class="float-end">£{{claims.fuel.recovery.total.us.toLocaleString()}}</span></li> -->
                                                    <!-- <li class="list-group-item">{{$t('dashboard.active_parking_sites')}}<span class="float-end">{{claims.parking.sites.toLocaleString()}}</span></li> -->
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="card widget widget-stats-large">
                                <div class="row">
                                    <div class="col-xl-8">
                                        <div class="widget-stats-large-chart-container">
                                            <div class="card-header">
                                                <h5 class="card-title">{{$t('navigation.side.claims')}}<span class="badge badge-light badge-style-light">{{$t('sites.site.lifetime')}}</span></h5>
                                            </div>
                                            <div class="card-body" style="position: relative;">
                                                <vue-apex-charts type="bar" :options="charts.statusBreakdown.chartOptions" :series="charts.statusBreakdown.series"></vue-apex-charts>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="widget-stats-large-info-container">
                                            <div class="card-header">
                                                <h5 class="card-title">By Status</h5>
                                            </div>
                                            <div class="card-body">
                                                <p class="card-description text-muted">{{$t('dashboard.chart_description')}}</p>
                                                <ul class="list-group list-group-flush text-light">
                                                    <li class="list-group-item" v-for="status in statusBreakdown">{{ status.status }}<span class="float-end">{{parseFloat(status.count).toLocaleString()}} (£{{ status.value }})</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" style="margin-left: -175px;" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" style="margin-right: -175px;" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

            </div>
        </div>

        <div v-if="showBlacklist" class="row">
            <!-- Recovery via blacklist -->
            <div class="col-xl-12">
                <!-- Hidden heading to align both blacklist cards -->
                <h4 class="section-heading p-v-sm no-m"> {{$t('dashboard.plp').toUpperCase()}} </h4>
                <div class="card" id="plp-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-10">
                                {{$t('dashboard.plp_desc')}} 
                            </div>
                            <div class="col-2">
                                <i class="material-icons" style="float: right;"> info </i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" id="plp-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group m-b-sm form-heading">
                                    <select v-model="blacklistSavingsTime" class="form-select" @change="getBlacklistSavings">
                                        <option value="day" selected> {{$t('dashboard.today')}} </option>
                                        <option value="week"> {{$t('dashboard.this', {text: 'Week'})}}</option>
                                        <option value="month"> {{$t('dashboard.this', {text: 'Month'})}}</option>
                                        <option value="year"> {{$t('dashboard.this', {text: 'Year'})}}</option>
                                    </select>
                                 </div>

                                 <br/>

                                 <h2 class="text-success text-center" style="font-size: 72px; font-weight: bold;">£{{parseFloat(blacklistSavings || 0.00).toLocaleString()}}</h2>
                                 <p class="text-center" style="font-size: 20px;">£{{(blacklistAvg || 0.00).toLocaleString()}} Average Drive Off</p>
                                 
                                 <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 id="oldData" class="section-heading p-v-sm"> {{$t('dashboard.graphs').toUpperCase()}} </h4>
        <div class="row">

            <!-- Fuel Recovery -->
            <div class="col-xl-6 m-b-md">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header" style="padding-bottom: 10px;">
                        <h5 class="card-title">{{$t('dashboard.fuel_recovery')}}<span class="badge badge-light badge-style-light" style="float: right;">{{$t('dashboard.last_twelve_months')}}</span></h5>
                        <h5> <i class="material-icons-outlined" style="vertical-align: middle; font-size: 1.5rem;"> {{currency}} </i> {{charts.payments.paidTotal.toLocaleString()}} (<i class="material-icons-outlined" style="vertical-align: middle; font-size: 1.5rem;"> {{currency}} </i> {{charts.payments.siteTotal.toLocaleString()}} paid on site) </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.payments.chartOptions" :series="charts.payments.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Parking -->
            <div class="col-xl-6 m-b-md">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header" style="padding-bottom: 10px;">
                        <h5 class="card-title">{{$t('dashboard.parking')}}<span class="badge badge-light badge-style-light" style="float: right;">{{$t('dashboard.last_twelve_months')}}</span></h5>
                        <h5> <i class="material-icons-outlined" style="vertical-align: middle; font-size: 1.5rem;"> {{currency}} </i> {{charts.parking.totalValue.toLocaleString()}} </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.parking.chartOptions" :series="charts.parking.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Blacklisted Vehicle -->
            <!-- <div v-if="showBlacklist" class="col-xl-6 m-b-md">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header" style="padding-bottom: 10px;">
                        <h5 class="card-title">{{$t('dashboard.blacklisted_vehicle')}}<span class="badge badge-light badge-style-light" style="float: right;">{{$t('dashboard.last_twelve_months')}}</span></h5>
                        <h5> {{charts.blacklistVehicle.totalValue}} </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.blacklistVehicle.chartOptions" :series="charts.blacklistVehicle.series"></vue-apex-charts>
                    </div>
                </div>
            </div> -->

            <!-- Number of Claims -->
            <div v-if="showBlacklist" class="col-xl-6 m-b-md">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header" style="padding-bottom: 10px;">
                        <h5 class="card-title">{{$t('dashboard.claims_number')}}<span class="badge badge-light badge-style-light" style="float: right;">{{$t('dashboard.last_twelve_months')}}</span></h5>
                        <h5> {{charts.numberOfClaims.totalValue}} </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="bar" :options="charts.numberOfClaims.chartOptions" :series="charts.numberOfClaims.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Potential Blacklist Savings -->
            <div v-if="showBlacklist" class="col-xl-6 m-b-md">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header" style="padding-bottom: 10px;">
                        <h5 class="card-title">{{$t('dashboard.potential_blacklist_savings')}}<span class="badge badge-light badge-style-light" style="float: right;">{{$t('dashboard.last_twelve_months')}}</span></h5>
                        <h6 class="card-sub-title"><span style="float: right;">Based on your average drive off per vehicle</span></h6>
                        <h5> <i class="material-icons-outlined" style="vertical-align: middle; font-size: 1.5rem;"> {{currency}} </i> {{charts.potentialBlacklist.totalValue.toLocaleString()}}</h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="line" :options="charts.potentialBlacklist.chartOptions" :series="charts.potentialBlacklist.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Claims Reported -->
            <div class="col-xl-6 m-b-md">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header" style="padding-bottom: 10px;">
                        <h5 class="card-title">{{$t('dashboard.claims_value')}}<span class="badge badge-light badge-style-light" style="float: right;">{{$t('dashboard.last_twelve_months')}}</span></h5>
                        <h5> <i class="material-icons-outlined" style="vertical-align: middle; font-size: 1.5rem;"> {{currency}} </i> {{charts.claimsReported.totalValue.toLocaleString()}} </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="line" :options="charts.claimsReported.chartOptions" :series="charts.claimsReported.series"></vue-apex-charts>
                    </div>
                </div>
            </div>

            <!-- Cancellations per month -->
             <div class="col-xl-6 m-b-md">
                <div class="widget-stats-large-chart-container">
                    <div class="card-header" style="padding-bottom: 10px;">
                        <h5 class="card-title">{{$t('dashboard.cancellations_per_month')}}<span class="badge badge-light badge-style-light" style="float: right;">{{$t('dashboard.last_twelve_months')}}</span></h5>
                        <h5> <i class="material-icons-outlined" style="vertical-align: middle; font-size: 1.5rem;"> {{currency}} </i> {{charts.cancellations.totalValue.toLocaleString()}} </h5>
                    </div>
                    <div class="card-body" style="position: relative;">
                        <vue-apex-charts type="line" :options="charts.cancellations.chartOptions" :series="charts.cancellations.series"></vue-apex-charts>
                    </div>
                </div>
            </div> 
        </div>

        <div class="row" id="daily-statistics">
            <h4 class="section-heading m-b-sm"> {{"DAILY STATISTICS"}} </h4>
            <div class="card-body row space-between">
                <div class="card">
                    <div class="card widget widget-stats todays-data">
                        <div class="card-body">
                            <div class="widget-stats-container d-flex">
                                <div style="margin: auto;">
                                    <div class="widget-stats-icon widget-stats-icon-primary">
                                        <i class="material-icons-outlined"> circle </i>
                                    </div>   
                                </div>
                                <div class="widget-stats-content flex-fill">
                                    <span class="card-body" style="position: relative;">
                                        <vue-apex-charts type="bar" :options="charts.DailyStatistics.chartOptions" :series="charts.DailyStatistics.series"></vue-apex-charts>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- Banner Modal -->
    <div class="modal fade" id="supportLineModal" tabindex="-1" aria-labelledby="supportLineModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="supportLineModalLabel">New Support Number - Technical Support Only</h5>
                    <button type="button" style="color: white;" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>You can now contact our support team directly using the phone number 0808 175 1100</p>
                    <p>Please be aware that our support team can only answer calls during the hours listed below:</p>
                    <ul>
                        <li>Monday - Friday: 8:00am - 5:00pm</li>
                        <li>Saturday: Closed</li>
                        <li>Sunday: Closed</li>
                        <li>Bank Holidays: Closed</li>
                    </ul>

                    <p><span class="text-warning">IMPORTANT!</span> Please do not provide this number to customers with a notice as they will not be able to get through to us!</p>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="awaitingEvidenceModal" tabindex="-1" aria-labelledby="awaitingEvidenceModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header" style="margin-inline: auto;">
                    <h5 class="modal-title">Incidents Awaiting Evidence</h5>
                    <button type="button" style="color: white;" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="margin-inline: auto;" v-if="aeincidents.incidents">
                    <p>{{ "There are currently " + this.aeincidents.total + " Incidents Awaiting new Evidence" }}</p>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" v-for="(incident, index) in incidentsWithTimeLimit" :key="index">
                            <div class="d-flex justify-content-between" @click="goToIncidents(incident.id, selectedSite)">
                                <span>{{ "Incident " + incident.id }}</span>
                                <span :class="{
                                    'text-success': incident.daysLeft > 10,
                                    'text-danger': incident.daysLeft < 4,
                                    'text-warning': incident.daysLeft >= 4 && incident.daysLeft <= 10 || incident.daysLeft === 'Undetermined'
                                }">
                                    {{ incident.daysLeft + " days left" }}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
import dashboardServices from '../../../services/dashboard.services';
import clientService from '../../../services/client.service';
import Loader from './Loader.vue';
import VueApexCharts from 'vue3-apexcharts';
import {DateTime} from 'luxon';
import {DATES} from '../../../constants/constants';
import { Multiselect } from 'vue-multiselect';

// TODO: Adjust routing to include selected site and site group

export default {
    name: 'StandardNew',
    components: {
        Loader,
        VueApexCharts,
        Multiselect
    },
    mounted() {
        this.getSites();
        this.getSiteGroups();
        this.loadData();
        this.setDates();

        let supportLineModal = new bootstrap.Modal(document.getElementById('supportLineModal'), {
            keyboard: false
        });

        if (localStorage.getItem('supportLineModal') === null) {
            supportLineModal.show();
            localStorage.setItem('supportLineModal', 'true');
        }

        this.awaitingEvidenceModal = new bootstrap.Modal(document.getElementById('awaitingEvidenceModal'), {
            keyboard: false
        });

    },
    props: {
        id: Number
    },
    data() {
        return {
            loading: true,
            sites: [],
            site: null,
            siteFilter: "Select a Site",
            selectedSite: -1,
            siteGroups: [],
            siteGroup: null,
            selectedSiteGroup: -1,
            selectedTime: 'month',
            loadingTodaysData: true,
            blacklistSavingsTime: "month",
            blacklistSavings: null,
            blacklistSavingsEvents: 0,
            blacklistAvg: 0.00,
            claims: {
                current: {
                    current_month: 0,
                    current_year: 0,
                    driveoff: 0,
                    last_month: 0,
                    money_lost: 0,
                    money_reclaimed: 0,
                    nmop: 0,
                    paid_on_site_current: 0,
                    paid_on_site_last: 0,
                    paid_on_site_total: 0,
                    parking: 0,
                    parking_curr_month: 0,
                    parking_last_month: 0,
                    parking_curr_year: 0,
                    parking_last_year: 0,
                    parking_sites: 0,
                    parking_total: 0,
                    paid_year: 0,
                    litres_outstanding: 0,
                    litres_recovered_year: 0,
                    litres_recovered_last: 0,
                    litres_recovered_current: 0
                },
                previous: {
                    paid_current: 0,
                    paid_last: 0,
                    paid_total: 0,
                    paid_year: 0,
                    money_lost: 0,
                    parking_paid_total: 0,
                    parking_paid_last: 0,
                    parking_paid_current: 0,
                    parking_paid_year: 0,
                    fuel_previous_last: 0,
                    fuel_previous_current: 0,
                    fuel_previous_total: 0,
                    litres_previous_year: 0,
                    litres_previous_last: 0,
                    litres_previous_current: 0,
                }
            },
            blacklist: {
                lastmonth: 0,
                lastweek: 0,
                month: 0,
                national: 0,
                today: 0,
                total: 0,
                week: 0,
                yesterday: 0
            },
            stats: {
                lost: {
                    today: 0,
                    yesterday: 0
                },
                paid: {
                    today: 0,
                    yesterday: 0
                },
                site: {
                    lost: 0,
                    site: {
                        id: -1,
                        name: 'No Site'
                    }
                },
                pump: {
                    lost: 0,
                    pump: 0
                }
            },
            charts: {
              payments: {
                chartOptions: {
                  chart: {
                    id: 'VARSPaymentsChart'
                  },
                  xaxis: {
                    type: 'datetime',
                    tickPlacement: 'on',
                    labels: {
                      formatter: function(timestamp){
                        return DateTime.fromMillis(timestamp).toFormat('MMM');
                      }
                    }
                  },
                  plotOptions: {
                    bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                    borderRadius: 10
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                  },
                  tooltip: {
                    theme: 'dark',
                    x: {
                        show: true,
                        formatter: function(timestamp) {
                            return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                        }
                    }
                    }
                },
                series: [],
                paidTotal: 0,
                siteTotal: 0
              },
              DailyStatistics: {
                chartOptions: {
                    chart: {
                        type: 'bar'
                    },
                    xaxis: {
                        tickPlacement: 'between',
                        categories: ['Total Events', 'Total Blacklist Events ', 
                        'Total Unique Vehicles', 'Drive Off Count', 'NMOP Count', 'CNAF Count']
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%', //width of the bar
                            endingShape: 'rounded',
                            borderRadius: 10
                        },
                    },
                    fill: {
                        colors: ['#0384fc'],
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                        }
                    }
                },
                series: []
              },
              claims: {
                chartOptions: {
                    chart: {
                        id: 'VARSProcessingClaimsChart',
                        type: 'bar'
                    },
                    xaxis: {
                        tickPlacement: 'between',
                        categories: ['Driveoff', 'No Means of Payment', 'Parking', 'Customer Not at Fault']
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%', //width of the bar
                            endingShape: 'rounded',
                            borderRadius: 10
                        },
                    },
                    fill: {
                        colors: ['#775DD0'],
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: []
              },
              parking: {
                chartOptions: {
                    chart: {
                        id: 'VARSParkingChart',
                        type: 'bar'
                    },
                    xaxis: {
                        type: 'datetime',
                        tickPlacement: 'on',
                        labels: {
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMM');
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                            borderRadius: 10
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: [],
                totalValue: 0
              },
              potentialBlacklist: {
                chartOptions: {
                    chart: {
                        id: 'VARSPotentialBlacklistChart',
                        type: 'line',
                        height: 350
                    },
                    xaxis: {
                        type: 'datetime',
                        tickPlacement: 'between',
                        labels: {
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMM')
                            }
                        }
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    markers: {
                        size: 5
                    },
                    fill: {
                        // colors: ['#775DD0'],
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: [],
                totalValue: 0
              },
              claimsReported: {
                chartOptions: {
                    chart: {
                        id: 'VARSClaimsReportedChart',
                        type: 'line'
                    },
                    xaxis: {
                        type: 'datetime',
                        tickPlacement: 'between',
                        labels: {
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMM')
                            }
                        }
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    markers: {
                        size: 5
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: [],
                totalValue: 0
              },
              blacklistVehicle: {
                chartOptions: {
                    chart: {
                        id: 'VARSBlacklistedVehicleChart',
                        type: 'bar'
                    },
                    xaxis: {
                        type: 'datetime',
                        tickPlacement: 'off',
                        labels: {
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMM');
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded',
                            borderRadius: 5
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: [],
                totalValue: 0
              },
              numberOfClaims: {
                chartOptions: {
                    chart: {
                        id: 'VARSNumberOfClaimsChart',
                        type: 'bar',
                        stacked: true // Enable stacked bars
                    },
                    xaxis: {
                        type: 'datetime',
                        tickPlacement: 'off',
                        labels: {
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMM');
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded',
                            borderRadius: 5
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: [],
                totalValue: 0
              },
              cancellations: {
                chartOptions: {
                    chart: {
                        id: 'VARSCancellationsChart',
                        type: 'line'
                    },
                    xaxis: {
                        type: 'datetime',
                        tickPlacement: 'on',
                        formatter: function(timestamp) {
                            return DateTime.fromMillis(timestamp).toFormat('MMM')
                        }
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    markers: {
                        size: 5
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: [],
                totalValue: 0
              },
              statusBreakdown: {
                chartOptions: {
                    chart: {
                        id: 'VARSProcessingClaimsChart',
                        type: 'bar'
                    },
                    xaxis: {
                        tickPlacement: 'between',
                        categories: ['Driveoff', 'No Means of Payment', 'Parking', 'Customer Not at Fault']
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%', //width of the bar
                            endingShape: 'rounded',
                            borderRadius: 10
                        },
                    },
                    fill: {
                        colors: ['#775DD0'],
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true,
                            formatter: function(timestamp) {
                                return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                            }
                        }
                    }
                },
                series: []
              },
            },
            tabs: {
                recovery: false,
                lost: false,
                parking: false,
                fuel_recovery: false,
                fuel_lost: false
            },
            dates: {
                today: {
                    start: null,
                    end: null
                },
                this_week: {
                    start: null,
                    end: null
                },
                this_month: {
                    start: null,
                    end: null
                },
                last_month: {
                    start: null,
                    end: null
                },
                this_year: {
                    start: null,
                    end: null
                }
            },
            datesObj: DATES,
            previousYear: {
                last_month: {
                    start: null,
                    end: null
                },
                month: {
                    start: null,
                    end: null
                },
                year: {
                    start: null,
                    end: null
                }
            },
            statusBreakdown: [],
            aeincidents: [],
            awaitingEvidenceModal: null,
            gateway_timeout: 0
        }
    },
    watch: {
        selectedSite(newVal, oldVal) {
            if (newVal !== -1) {
                this.getAwaitingEvidenceIncidents();
            }
        }
    },
    methods: {
        removeSiteGroup(){
            this.loadSiteGroupData(null);
        },
        removeSite(){
            this.loadData(null, this.siteGroup);
        },
        resetSiteFilter() {
            this.selectedSite = -1;
            this.site = null;
            this.siteGroup = null;
            this.loadData(null, this.siteGroup);
        },
        loadSiteGroupData(siteGroup = null){
            this.selectedSite = -1;
            this.site = null;
            if(siteGroup !== null && siteGroup !== undefined){
                this.selectedSiteGroup = siteGroup?.id || -1;
            }
            this.getSites();
            this.loadData(this.selectedSite, siteGroup);
        },
        loadData(site = null, siteGroup = null) {
            this.loading = true;
            if(site !== null && site !== undefined){
                this.selectedSite = site?.id || -1;
            }else{
                this.selectedSite = -1;
            }
            if(this.selectedSite !== -1){
                this.site = this.sites.find(s => parseInt(s.id) === parseInt(this.selectedSite));
            }
            if(siteGroup !== null && siteGroup !== undefined){
                this.selectedSiteGroup = siteGroup?.id || -1;
            }else{
                this.selectedSiteGroup = -1;
            }
            this.charts.claims.series = [];
            dashboardServices.getDashboardData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                for(let key in response.data.claims) {
                    this.claims.current[key] = parseFloat(response.data.claims[key] || 0);
                }

                let noOfClaims = [(this.claims.current.driveoff || 0 ), (this.claims.current.nmop || 0 ), (this.claims.current.parking || 0 ), (this.claims.current.cnaf || 0)];
                this.charts.claims.series.push({data: noOfClaims});
                
                this.blacklist = response.data.blacklist;
                this.loading = false;
            })
            .catch(error => {
                this.$error(this.$t('dashboard.error_msg'), error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
                this.loading = false;
            })
            this.todaysData();
            this.loadPreviousYearsData();
            this.fuelRecoveryChart();
            this.parkingRevenueChart();
            this.potentialblacklist();
            this.claimsReportedChart();
            this.blacklistedVehicleChart();
            this.cancellationsChart();
            this.dailyStatistics();
            this.getBlacklistSavings();
            this.getStatusBreakdown();
            this.numberOfClaimsChart();
        },
        todaysData() {
            this.loadingTodaysData = true;
            dashboardServices.getTodayStats(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedTime, this.selectedSiteGroup)
            .then(response => {
                this.stats = response.data;
                this.loadingTodaysData = false;
            })
            .catch(error => {
                console.log('Failed to load todays data', error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
                this.loadingTodaysData = false;
            })
        },
        getSites() {
            clientService.getClientSites(this.$store.state.auth.user.selectedClient, false, this.selectedSiteGroup)
            .then(response => {
                response.data = response.data.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                response.data.forEach((site) => {
                    if(site.customer_reference){
                        site.name = `(${site.customer_reference}) ${site.name}`;
                    }
                })
                this.sites = response.data;
                if(this.sites.length > 0 && this.sites.length < 2){
                    this.site = this.sites[0];
                }
            })
            .catch(error => {
                this.$error(this.$t('sites.site_load_error'), error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        getSiteGroups(){
            clientService.getClientSiteGroups(this.$store.state.auth.user.selectedClient)
            .then(response => {
                this.siteGroups = response.data;
            })
            .catch(error => {
                this.$error(this.$t('sites.site_load_error'), error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            });
        },
        getBlacklistSavings(){
            let start = DateTime.local().startOf(this.blacklistSavingsTime).toFormat('yyyy-MM-dd HH:mm:ss.SSS');
            let end = DateTime.local().endOf(this.blacklistSavingsTime).toFormat('yyyy-MM-dd HH:mm:ss.SSS');
            dashboardServices.getBlacklistSavings(this.$store.state.auth.user.selectedClient, this.selectedSite, start, end, this.selectedSiteGroup)
            .then(response => {
                this.blacklistSavings = response.data.savings;
                this.blacklistAvg = response.data.average;
                this.blacklistSavingsEvents = response.data.blacklist;
            })
            .catch(error => {
                this.$error("Failed to load blacklist savings", error);
            if (error.response.status === 504) {
              this.gateway_timeout += 1;
            }
            })
        },
        getStatusBreakdown(){
            dashboardServices.getStatusBreakdown(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                this.statusBreakdown = response.data.claims;
                this.statusBreakdown.forEach((status) => {
                    status.value = parseFloat(status.value || 0).toLocaleString();
                })

                let statuses = [];
                let data = [];
                this.statusBreakdown.forEach((status) => {
                    statuses.push(status.status);
                    data.push(parseFloat(status.count || 0));
                });

                this.charts.statusBreakdown.chartOptions.xaxis.categories = statuses;
                this.charts.statusBreakdown.series = [{data: data}];
            })
            .catch(error => {
                this.$error("Failed to load status breakdown", error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        getAwaitingEvidenceIncidents() {
            if (this.selectedSite === -1) return;
            dashboardServices.getAwaitingEvidenceIncidents(this.selectedSite)
            .then(response => {
                this.aeincidents = response.data;

                if (this.aeincidents.incidents && this.aeincidents.incidents.length > 0) {
                    this.awaitingEvidenceModal.show();
                }
            })
            .catch(error => {
                this.$error("Failed to load awaiting evidence incidents", error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        goToIncidents(incident_id, site_id) {
            this.awaitingEvidenceModal.hide();
            this.$router.push(`/incidents/${incident_id}?site_id=${site_id}`);
        },
        openReports(type) {
            this.$router.push({name: 'reports', params: {type: type, status: 'outstanding', site: this.selectedSite === -1 ? null : this.selectedSite}});
        },
        toggleTab(elem) {
            this.tabs[elem] = !this.tabs[elem];
        },
        loadPreviousYearsData(){
            dashboardServices.getPreviousYearData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                for(let key in response.data.claims) {
                    this.claims.previous[key] = parseFloat(response.data.claims[key] || 0);
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        openTodaysReport(obj) {
            console.log("Selected Time: ", this.selectedTime);

            let dateObject = {
                'day': this.dates.today,
                'week': this.dates.this_week,
                'month': this.dates.this_month,
                'year': this.dates.this_year
            }[this.selectedTime];
            console.log(dateObject);

            console.log("Selected Site: ", this.selectedSite);

            let params = {
                site: (this.selectedSite === -1 ? null : parseInt(this.selectedSite)),
                group: (this.selectedSiteGroup === -1 ? null : parseInt(this.selectedSiteGroup)),
                start_date: dateObject?.start || null,
                end_date: dateObject?.end || null
            }

            for(let key in obj) {
                params[key] = obj[key];
            }

            console.log("Stage 2: ", params);

            this.$router.push({name: 'reports', params: params});
        },
        percentageChange(oldVal, newVal) {
            if(oldVal === 0 && newVal === 0) {
                return {icon: 'arrow_right_alt', value: 0.00}
            };
            if(oldVal < newVal && oldVal === 0){
                return {icon: 'keyboard_arrow_up', value: 100.00}
            }
            let decrease = newVal - oldVal;
            if(decrease < 0) {
                return {icon: 'keyboard_arrow_down', value: Math.abs(decrease /oldVal * 100).toFixed(2)}
            }else {
                return {icon: 'keyboard_arrow_up', value: Math.abs(decrease /oldVal * 100).toFixed(2)}
            }
        },
        blacklistPercIndicator(icon) {
            if(icon === 'keyboard_arrow_up') {
                return 'perc-increase';
            }
            if(icon === 'keyboard_arrow_down') {
                return 'perc-decrease';
            }
            if(icon === 'arrow_right_alt'){
                return 'perc-neutral';
            }
        },
        setDates() {
            let today = DateTime.local();
            this.dates.today.start = today.toFormat('yyyy-MM-dd');
            this.dates.today.end = today.toFormat('yyyy-MM-dd');
            this.dates.this_week.start = today.startOf('week').toFormat('yyyy-MM-dd');
            this.dates.this_week.end = today.endOf('week').toFormat('yyyy-MM-dd');
            this.dates.this_month.start = today.startOf('month').toFormat('yyyy-MM-dd');
            this.dates.this_month.end = today.endOf('month').toFormat('yyyy-MM-dd');
            this.dates.last_month.start = today.minus({month: 1}).startOf('month').toFormat('yyyy-MM-dd');
            this.dates.last_month.end = today.minus({month: 1}).startOf('month').toFormat('yyyy-MM-dd');
            this.dates.this_year.start = today.startOf('year').toFormat('yyyy-MM-dd');
            this.dates.this_year.end = today.endOf('year').toFormat('yyyy-MM-dd');
            this.previousYear.last_month.start = DateTime.fromFormat(this.datesObj.last_month.start_date, 'yyyy-MM-dd').minus({year: 1}).toFormat('yyyy-MM-dd');
            this.previousYear.last_month.end = DateTime.fromFormat(this.datesObj.last_month.end_date, 'yyyy-MM-dd').minus({year: 1}).toFormat('yyyy-MM-dd');
            this.previousYear.month.start = DateTime.fromFormat(this.datesObj.this_month.start_date, 'yyyy-MM-dd').minus({year: 1}).toFormat('yyyy-MM-dd');
            this.previousYear.month.end = DateTime.fromFormat(this.datesObj.this_month.end_date, 'yyyy-MM-dd').minus({year: 1}).toFormat('yyyy-MM-dd');
            this.previousYear.year.start = DateTime.fromFormat(this.datesObj.this_year.start_date, 'yyyy-MM-dd').minus({year: 1}).toFormat('yyyy-MM-dd');
            this.previousYear.year.end = DateTime.fromFormat(this.datesObj.this_year.end_date, 'yyyy-MM-dd').minus({year: 1}).toFormat('yyyy-MM-dd');
        },
        dragStart(event) {
            const target = event.target;
            event.dataTransfer.setData('card_id', target.id);

            setTimeout(() => {
                target.style.display = none;
            }, 0);
        },
        fuelRecoveryChart() {
            // reset for creating site specific chart
            this.charts.payments.series = [];
            dashboardServices.getRecoveryChartData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                let dates = [];
                let paid = [];
                let paid_onsite = [];
                this.charts.payments.paidTotal = 0;
                this.charts.payments.siteTotal = 0;
                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                    this.charts.payments.paidTotal += parseFloat(elem.paid);
                });

                response.data.other_data.forEach(elem => {
                    paid_onsite.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                    this.charts.payments.siteTotal += parseFloat(elem.paid);
                });

                this.charts.payments.series.push({name: this.$t('dashboard.money_recovered'), data: paid});
                this.charts.payments.series.push({name: this.$t('dashboard.recovered_on_site'), data: paid_onsite});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        parkingRevenueChart() {
            this.charts.parking.series = [];
            dashboardServices.getParkingChartData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                let dates = [];
                let paid = [];
                this.charts.parking.totalValue = 0;
                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                    this.charts.parking.totalValue += elem.paid;
                });

                this.charts.parking.series.push({name: this.$t('dashboard.parking_revenue'), data: paid});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        potentialblacklist() {
            this.charts.potentialBlacklist.series = [];
            dashboardServices.getPotentialBlacklistChartData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                let dates = [];
                let paid = [];
                this.charts.potentialBlacklist.totalValue = 0;
                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                    this.charts.potentialBlacklist.totalValue += elem.paid;
                });
                this.charts.potentialBlacklist.series.push({name: this.$t('dashboard.potential_blacklist_savings'), data: paid});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        claimsReportedChart() {
            this.charts.claimsReported.series = [];
            dashboardServices.getDriveoffReportedChartData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                let dates = [];
                let paid = [];
                this.charts.claimsReported.totalValue = 0;
                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                    this.charts.claimsReported.totalValue += parseFloat(elem.paid);
                });
                this.charts.claimsReported.series.push({name: this.$t('dashboard.claims_reported'), data: paid});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        blacklistedVehicleChart() {
            this.charts.blacklistVehicle.series = [];
            dashboardServices.getBlacklistedVehicleChartData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                let dates = [];
                let paid = [];
                this.charts.blacklistVehicle.totalValue = 0;
                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid)]);
                    this.charts.blacklistVehicle.totalValue += parseFloat(elem.paid);
                });
                this.charts.blacklistVehicle.series.push({name: this.$t('dashboard.blacklisted_vehicle'), data: paid});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        numberOfClaimsChart() {
            this.charts.numberOfClaims.series = [];
            dashboardServices.getNumberOfClaimsChartData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                let dates = [];
                let cnafData = [];
                let driveoffData = [];
                let nmopData = [];
                this.charts.numberOfClaims.totalValue = 0;

                // Extract and format data for each type of claim
                response.data.claims.cnaf.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if (!dates.includes(month)) {
                        dates.push(month);
                    }
                    cnafData.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.cnaf)]);
                    this.charts.numberOfClaims.totalValue += parseFloat(elem.cnaf);
                });

                response.data.claims.driveoff.forEach(elem => {
                    driveoffData.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.driveoff)]);
                    this.charts.numberOfClaims.totalValue += parseFloat(elem.driveoff);
                });

                response.data.claims.nmop.forEach(elem => {
                    nmopData.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.nmop)]);
                    this.charts.numberOfClaims.totalValue += parseFloat(elem.nmop);
                });

                // Add series for each type of claim
                this.charts.numberOfClaims.series.push({ name: 'CNAF', data: cnafData });
                this.charts.numberOfClaims.series.push({ name: 'Driveoff', data: driveoffData });
                this.charts.numberOfClaims.series.push({ name: 'NMOP', data: nmopData });
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
                if (error.response.status === 504) {
                    this.gateway_timeout += 1;
                }
            });
        },
        cancellationsChart() {
            this.charts.cancellations.series = [];
            dashboardServices.getCancellationsChartData(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                let dates = [];
                let paid = [];
                this.charts.cancellations.totalValue = 0;
                response.data.data.forEach(elem => {
                    let month = DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toFormat('MMM');
                    if(!dates.includes(month)) {
                        dates.push(month);
                    }
                    paid.push([DateTime.fromFormat(`${elem.month}/${elem.year}`, 'M/yyyy').toJSDate(), parseFloat(elem.paid).toFixed(2)]);
                    this.charts.cancellations.totalValue += parseFloat(elem.paid);
                });
                this.charts.cancellations.series.push({name: this.$t('dashboard.cancellations'), data: paid});
            })
            .catch(error => {
                console.log('Failed to load charts data', error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        },
        dailyStatistics() {
            this.charts.DailyStatistics.series = [];
            dashboardServices.getDailyStats(this.$store.state.auth.user.selectedClient, this.selectedSite, this.selectedSiteGroup)
            .then(response => {
                this.charts.DailyStatistics.series.push({data: Object.values(response.data.dailystatistics[0])});
                this.loading = false;
            })
            .catch(error => {
                this.$error(('Failed to load Daily Data'), error);
                if (error.response.status === 504) {
                this.gateway_timeout += 1;
                }
            })
        }
    },
    computed: {
        paid_percentage: function() {
            let difference = this.stats.paid.today - this.stats.paid.yesterday;
        
            if(this.stats.paid.yesterday !== 0 && this.stats.paid.yesterday !== null) {
                if(difference < 0) {
                    return {icon: 'keyboard_arrow_down', value: Math.abs(difference /this.stats.paid.yesterday * 100).toFixed(2)}
                } else {
                    return {icon: 'keyboard_arrow_up', value: Math.abs(difference /this.stats.paid.yesterday * 100).toFixed(2)}
                }
            } else {
                //if nothing was paid yesterday then it is a 100% increase in today's payment
                return {icon: 'keyboard_arrow_up', value: 100}
            }
        },
        lost_percentage: function() {
            let difference = this.stats.lost.today - this.stats.lost.yesterday;

            if(this.stats.lost.yesterday !== 0 && this.stats.lost.yesterday !== null) {
                if(difference < 0) {
                    return {icon: 'keyboard_arrow_down', value: Math.abs(difference / this.stats.lost.yesterday * 100).toFixed(2)};
                } else{
                    return {icon: 'keyboard_arrow_up', value: Math.abs(difference / this.stats.lost.yesterday * 100).toFixed(2)};
                }
            } else {
                return {icon: 'keyboard_arrow_up', value: 100}
            }
        },
        currency: function() {
            //selected_client.locale does not changes value on changing countries
            return this.$i18n.locale === 'en-GB' ? 'currency_pound' : 'euro'
        },
        total_claims: function() {
            return (this.claims.current.driveoff + this.claims.current.nmop + this.claims.current.parking);
        },
        current_month: function() {
            return DateTime.local().monthLong;
        },
        previous_month: function() {
            return DateTime.local().minus({month: 1}).monthLong;
        },
        current_year: function() {
            return DateTime.local().year;
        },
        previous_year: function() {
            return DateTime.local().minus({year: 1}).year;
        },
        previous_month_previous_year: function(){
            return DateTime.local().minus({year: 1, month: 1}).year;
        },
        blacklistTodayChange: function(){
            return this.percentageChange(this.blacklist.yesterday, this.blacklist.today);
        },
        blacklistWeekChange: function(){
            return this.percentageChange(this.blacklist.lastweek, this.blacklist.week);
        },
        blacklistMonthChange: function(){
            return this.percentageChange(this.blacklist.lastmonth, this.blacklist.month);
        },
        blacklistYearChange: function(){
            return this.percentageChange(this.blacklist.lastyear, this.blacklist.year);
        },
        data_heading: function() {
            switch(this.selectedTime) {
                case 'day': 
                    return {heading: 'dashboard.todays_data', day: 'dashboard.yesterday'};
                case 'week':
                    return {heading: 'dashboard.weeks_data', day: 'dashboard.last_week'};
                case 'month':
                    return {heading: 'dashboard.months_data', day: 'dashboard.last_month'};
                case 'year': 
                    return {heading: 'dashboard.years_data', day: 'dashboard.last_year'};
                case 'lastyear':
                    return {heading: 'dashboard.last_years_data', day: 'dashboard.last_year'};
            }
        },
        showBlacklist: function(){
            if((this.blacklist.yesterday + this.blacklist.today + this.blacklist.lastweek + this.blacklist.week + this.blacklist.lastmonth + this.blacklist.month) > 0){
                return true;
            } else {
                return false;
            }
        },
        projectedRecovery: function(){
            let startOfMonth = DateTime.local().startOf('month');
            let today = DateTime.local();
            let daysSoFar = today.diff(startOfMonth, 'days').days;
            let daysInTotal = today.daysInMonth;

            return 'Forecast: £' + (this.stats.paid.today / daysSoFar * daysInTotal).toFixed(2);
        },
        projectedLosses: function(){
            let startOfMonth = DateTime.local().startOf('month');
            let today = DateTime.local();
            let daysSoFar = today.diff(startOfMonth, 'days').days;
            let daysInTotal = today.daysInMonth;

            return 'Forecast: £' + (this.stats.lost.today / daysSoFar * daysInTotal).toFixed(2);
        },
        projectedRecoveryYear: function(){
            let startOfYear = DateTime.local().startOf('year');
            let today = DateTime.local();
            let daysSoFar = today.diff(startOfYear, 'days').days;
            let daysInTotal = today.daysInYear;

            return 'Forecast: £' + (this.claims.current.current_year / daysSoFar * daysInTotal).toLocaleString();
        },
        projectedParking: function(){
            let startOfMonth = DateTime.local().startOf('month');
            let today = DateTime.local();
            let daysSoFar = today.diff(startOfMonth, 'days').days;
            let daysInTotal = today.daysInMonth;

            return 'Forecast: £' + (this.claims.current.parking_curr_month / daysSoFar * daysInTotal).toFixed(2);
        },
        projectedParkingYear(){
            let startOfYear = DateTime.local().startOf('year');
            let today = DateTime.local();
            let daysSoFar = today.diff(startOfYear, 'days').days;
            let daysInTotal = today.daysInYear;

            return 'Forecast: £' + (this.claims.current.parking_curr_year / daysSoFar * daysInTotal).toFixed(2);
        },
        paidOnSitePerc(){
            let paidOnSite = parseFloat(this.claims?.current?.paid_on_site_current || 0);
            if(this.selectedTime == 'year'){
                paidOnSite = parseFloat(this.claims?.current?.paid_on_site_year || 0);
            }
            let paid = parseFloat(this.stats.paid.today || 0);
            if(this.selectedTime == 'year'){
                paid = parseFloat(this.claims?.current?.current_year || 0);
            }
            let total = paidOnSite + paid;
            return total > 0 ? (paidOnSite / total * 100).toFixed(2) : 0;
        },
        incidentsWithTimeLimit() {
            const fourteenDaysInMillis = 14 * 24 * 60 * 60 * 1000;
            const currentDate = new Date();

            return this.aeincidents.incidents.map(incident => {
                if (!incident.latest_evidence) {
                    return {
                        ...incident,
                        daysLeft: 'Undetermined'
                    };
                } else {
                    const latestEvidenceDate = new Date(incident.latest_evidence);
                    const timeDiff = latestEvidenceDate.getTime() + fourteenDaysInMillis - currentDate.getTime();
                    const daysLeft = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));

                    return {
                        ...incident,
                        daysLeft
                    };
                }
            });
        },
        getTimeLimitClass(daysLeft) {
            if (typeof daysLeft !== 'number') {
                return '';
            }
            if (daysLeft > 10) {
                return 'text-success'; // Green
            } else if (daysLeft > 4) {
                return 'text-warning'; // Orange
            } else {
                return 'text-danger'; // Red
            }
        }
    }
}
</script>

<style scoped>
    .wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 60vh;
    }

    .sub-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-heading {
        font-weight: 400;
    }

    .list-group-item {
        font-weight: 400;
        cursor: pointer;
    }

    .recovery-stats.lost {
        width: 100%;
        padding: 10px 20px;
        background-color: var(--utility-red);
    }

    .recovery-stats.lost:hover {
        background-color: #FF4857;
        border-color: #FF4857;
    }

    .recovery-stats.active-sites {
        width: 100%;
        padding: 10px 20px;
        background-color: var(--utility-blue);
    }

    .recovery-stats .figure {
        font-size: 25px;
        font-weight: bold;
    }

    .center-text {
        padding: 10px;
        text-align: center;
        cursor: pointer;
    }

    .amount {
        font-size: 25px;
        font-weight: 600;
    }

    .perc-increase {
        background: rgba(75, 173, 72, .25)!important;
        color: var(--utility-green);
    }

    .perc-decrease {
        background: rgba(255, 72, 87, .25)!important;
        color: var(--utility-red);
    }

    .perc-neutral {
        background: rgba(255, 149, 0, .25)!important;
        color: var(--utility-orange);
    }

    .perc-increase, .perc-decrease, .perc-neutral {
        padding: 5px;
        border-radius: 5px;
    }

    .card-header {
        border: none;
        padding: 1.5rem 2rem 0 2rem;
        /* background-color: var(--bg-color-secondary); */
        border-radius: 10px;
    }

    .card-sub-title {
        color: var(--font-color-secondary);
        font-weight: 200;
    }

    .desc-icon {
        color: var(--font-color-secondary);
    }

    .desc-icon:hover {
        color: var(--font-color-primary);
    }

    .temp2 {
        background-color: #6939CE;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        position: relative;
    }

    .temp2 span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: 500;
    }

    .flex-center {
        flex-direction: column;
    }

    .carousel {
        padding: 0;
    }

    .carousel-item.active {
        display: flex;
        justify-content: space-between;
    }
    a {
    color: inherit;
    text-decoration: inherit;
    }
    .pseudobutton {
        display: inline-block;
        padding: 10px 20px;
        background-color: #2269f5;
        color: white;
        text-decoration: none;
        border-radius: 4px;
        transition: background-color 0.3s;
    }

</style>